import { Empty } from "antd";
import React from "react";

export const Z_NotFoundSample = () => {
  return (
    <div className="flex-row-center h-full">
      <Empty />
    </div>
  );
};
