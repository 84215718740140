import { Table } from "antd";
import React, { useEffect, useState, useRef } from "react";

export const SalesTable2 = () => {

  const [data, setData] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [total, setTotal] = useState(0);

  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState('');
  const [queueCount, setQueueCount] = useState(0);
  const reconnectAttempts = useRef(0);  // 재연결 시도 횟수
  const maxReconnectAttempts = 5;       // 최대 재연결 시도 횟수
  const reconnectInterval = 5000;       // 재연결 시도 간격 (5초)

  // 웹소켓 연결 설정 및 재연결 함수
  const connectWebSocket = () => {
    let accomId = sessionStorage.getItem("accomId");
    let event_listener_url = sessionStorage.getItem("event_listener_url");
    let token = sessionStorage.getItem("token");

    const ws = new WebSocket(event_listener_url + "?jwt="+token);

    ws.onopen = () => {
      console.log('WebSocket connected');
      reconnectAttempts.current = 0; // 연결 성공 시 재연결 시도 횟수 초기화
      
      setTimeout(() => {
        const joinMessage = JSON.stringify({
          command: 'JOIN',
          data: {
            accom_id: accomId,
            disable_event_changed_by_yourself : false,
            snapshot: [
              "TODAY_PAYMENT_STATISTICS"
            ]
          }
        });
        ws.send(joinMessage);
      }, 1000);
    };

    ws.onmessage = (event) => {
      console.log('Message from server:', event.data);
      const parsedData = JSON.parse(event.data);

      // queue_count 값 확인 및 ACCEPT 명령 전송
      if (parsedData.queue_count && parsedData.queue_count > 0) {
        setQueueCount(parsedData.queue_count);
        
        const acceptMessage = JSON.stringify({ command: 'ACCEPT' });
        ws.send(acceptMessage);
      }

      console.log(parsedData.data);

      if(typeof parsedData.data != "undefined"){
        const hoursMap = parsedData.data.today_payment_statistics.today.hours;
        const daysMap = parsedData.data.today_payment_statistics.today.days;
        const longDaysMap = parsedData.data.today_payment_statistics.today.long_days;
        const etcMap = parsedData.data.today_payment_statistics.today;

        setData([
          {
            key: "1",
            category: "대실",
            name: "현금",
            count: hoursMap.cash_count,
            value: hoursMap.cash_payment,
            unpauidCount: hoursMap.not_paid_count,
            unpauid: hoursMap.not_paid_payment,
          },
          {
            key: "2",
            category: "대실",
            name: "계좌",
            count: hoursMap.account_count,
            value: hoursMap.account_payment,
            unpauidCount: hoursMap.not_paid_count,
            unpauid: hoursMap.not_paid_payment,
          },
          {
            key: "3",
            category: "대실",
            name: "카드",
            count: hoursMap.card_count,
            value: hoursMap.card_payment,
            unpauidCount: hoursMap.not_paid_count,
            unpauid: hoursMap.not_paid_payment,
          },
          {
            key: "4",
            category: "대실",
            name: "OTA",
            count: hoursMap.ota_count,
            value: hoursMap.ota_payment,
            unpauidCount: hoursMap.not_paid_count,
            unpauid: hoursMap.not_paid_payment,
          },
      
          {
            key: "5",
            category: "숙박",
            name: "현금",
            count: daysMap.cash_count,
            value: daysMap.cash_payment,
            unpauidCount: daysMap.not_paid_count,
            unpauid: daysMap.not_paid_payment,
          },
          {
            key: "6",
            category: "숙박",
            name: "계좌",
            count: daysMap.account_count,
            value: daysMap.account_payment,
            unpauidCount: daysMap.not_paid_count,
            unpauid: daysMap.not_paid_payment,
          },
          {
            key: "7",
            category: "숙박",
            name: "카드",
            count: daysMap.card_count,
            value: daysMap.card_payment,
            unpauidCount: daysMap.not_paid_count,
            unpauid: daysMap.not_paid_payment,
          },
          {
            key: "8",
            category: "숙박",
            name: "OTA",
            count: daysMap.ota_count,
            value: daysMap.ota_payment,
            unpauidCount: daysMap.not_paid_count,
            unpauid: daysMap.not_paid_payment,
          },
      
          {
            key: "9",
            category: "장기",
            name: "현금",
            count: longDaysMap.cash_count,
            value: longDaysMap.cash_payment,
            unpauidCount: longDaysMap.not_paid_count,
            unpauid: longDaysMap.not_paid_payment,
          },
          {
            key: "10",
            category: "장기",
            name: "계좌",
            count: longDaysMap.account_count,
            value: longDaysMap.account_payment,
            unpauidCount: longDaysMap.not_paid_count,
            unpauid: longDaysMap.not_paid_payment,
          },
          {
            key: "11",
            category: "장기",
            name: "카드",
            count: longDaysMap.card_count,
            value: longDaysMap.card_payment,
            unpauidCount: longDaysMap.not_paid_count,
            unpauid: longDaysMap.not_paid_payment,
          },
          {
            key: "12",
            category: "장기",
            name: "OTA",
            count: longDaysMap.ota_count,
            value: longDaysMap.ota_payment,
            unpauidCount: longDaysMap.not_paid_count,
            unpauid: longDaysMap.not_paid_payment,
          },
      
          {
            key: "13",
            category: "기타",
            name: "",
            count: etcMap.other_type_count,
            value: etcMap.other_type_total,
            unpauidCount: 0,
            unpauid: 0,
          }
        ]);

        setTotalCount(etcMap.total_count);
        setTotal(etcMap.total);
      }
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
      setSocket(null);
      attemptReconnect(); // 연결이 끊어졌을 때 재연결 시도
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      setSocket(null);
      attemptReconnect();
    };

    setSocket(ws); // 소켓 객체 저장
  };

  // 재연결 시도 함수
  const attemptReconnect = () => {
    if (reconnectAttempts.current < maxReconnectAttempts) {
      reconnectAttempts.current += 1;
      console.log(`Reconnecting... attempt ${reconnectAttempts.current}`);
      
      // 일정 시간 후에 재연결 시도
      setTimeout(() => {
        connectWebSocket();
      }, reconnectInterval);
    } else {
      console.error('Max reconnect attempts reached.');
    }
  };

  useEffect(() => {
    if (!socket || socket.readyState === WebSocket.CLOSED || socket.readyState === WebSocket.CLOSING) {
      connectWebSocket();
    }

    return () => {
      if (socket && socket.readyState !== WebSocket.CLOSING) {
        socket.close();
        setSocket(null); // 소켓 초기화
      }
    };
  }, []);

  useEffect(() => {
    setGroupedData(data.reduce((acc, item) => {
      const lastGroup = acc[acc.length - 1];
      if (!lastGroup || lastGroup.category !== item.category) {
        acc.push({
          category: item.category,
          rows: [item],
          totalCount: item.count,
          totalValue: item.value,
        });
      } else {
        lastGroup.rows.push(item);
        lastGroup.totalCount += item.count;
        lastGroup.totalValue += item.value;
      }
      return acc;
    }, []));
  }, [data]);

  useEffect(() => {
    // DOM 업데이트 후에 rowspan 적용 및 hover 이벤트 추가
    const applyRowspanHoverEffect = () => {
      const rowspanCells = document.querySelectorAll(".rowspan-cell");
  
      rowspanCells.forEach((cell) => {
        const rowspan = cell.getAttribute("rowspan");
        const parentRow = cell.parentElement;
  
        // 그룹핑된 행을 추출
        const groupedRows = [];
        let currentRow = parentRow;
  
        // rowspan 수만큼 행을 추출
        for (let i = 0; i < rowspan; i++) {
          if (currentRow) {
            groupedRows.push(currentRow);
            currentRow = currentRow.nextElementSibling;
          }
        }
  
        // 부모 tr에 hover 이벤트 추가
        parentRow.addEventListener("mouseenter", () => {
          groupedRows.forEach((row) => {
            row.classList.add("highlight");
          });
        });
  
        parentRow.addEventListener("mouseleave", () => {
          groupedRows.forEach((row) => {
            row.classList.remove("highlight");
          });
        });
      });
    };
  
    // `groupedData`가 업데이트될 때마다 적용
    applyRowspanHoverEffect();
  }, [groupedData]);
  

  return (
    <>
      <div className="table-wrap">
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <colgroup>
            <col width="25%" />
            <col width="25%" />
            <col width="25%" />
            <col width="25%" />
          </colgroup>
          <thead>
            <tr>
              <th>카테고리</th>
              <th>종류</th>
              <th>건수</th>
              <th>금액</th>
            </tr>
          </thead>
          {groupedData.map((group, idx) => (
            <tbody key={`group-${idx}`}>
              {group.rows.length === 1 ? (
                <tr>
                  <td className="text-c">
                    <h3>{group.rows[0].category}</h3>
                  </td>
                  <td className="text-r"></td>
                  <td className="text-r">{group.rows[0].count} 건</td>
                  <td className="text-r">{group.rows[0].value}</td>
                </tr>
              ) : (
                <>
                  {group.rows.map((record, index) => (
                    <tr key={record.key}>
                      {index === 0 ? (
                        <td
                          rowSpan={group.rows.length}
                          className="rowspan-cell text-c"
                        >
                          <h3>{group.category}</h3>
                        </td>
                      ) : null}
                      <td className="text-c">{record.name}</td>
                      <td className="text-r">{record.count} 건</td>
                      <td className="text-r">
                        {record.value.toLocaleString()}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={2} className="text-c">
                      <strong>합계</strong>
                    </td>
                    <td className="text-r">
                      <strong>{group.totalCount} 건</strong>
                    </td>
                    <td className="text-r">
                      <strong>{group.totalValue.toLocaleString()}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} className="text-c">
                      <strong>미결제</strong>
                    </td>
                    <td className="text-r"><strong>{group.rows[0].unpauidCount} 건</strong></td>
                    <td className="text-r">
                      <strong>{group.rows[0].unpauid.toLocaleString()}</strong>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          ))}

          <tfoot>
            <tr>
              <td colSpan={2} className="text-c">
                <strong>총 합계</strong>
              </td>
              <td className="text-r"><strong>{totalCount.toLocaleString()}건</strong></td>
              <td className="text-r"><strong>{total.toLocaleString()}</strong></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};
