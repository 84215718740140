import React, { useCallback, useEffect, useState } from "react";
import koKR from "antd/lib/locale/ko_KR";
import { useCode } from "../login/CodeContext";
import { 
  Button, ConfigProvider, Divider,
  Table, Select, Input,
  InputNumber, Modal, message 
} from "antd";
import { AlertOutlined } from "@ant-design/icons";
import {
  doorLockAllByAccomSearchApi,
  doorLockInsertApi,
  doorLockUpdateApi,
  doorLockDeleteApi,
} from "../api/api";
import {debounce} from "lodash";
import { generateRandomString } from "../util";

export const DoorlockInfoManage = ({ roomAllList }) => {
  // console.log("roomAllList ==> ", roomAllList);
  // console.log("doorLockAllList ==> ", doorLockAllList);
  const [editRows, setEditRows] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });

  // 공통 코드 불러오는 부분
  const { coderead } = useCode();
  const code = coderead();
  const codeRoom = Object.entries(code.room);
  const codeRoomList = codeRoom.map(([key, value]) => {
    return {
      value: key,
      label: value.display_name,
    };
  });

  // 도어락 정보 (API 연결)
  const [doorLockAllList, setDoorLockAllList] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await doorLockAllByAccomSearchApi();
        const resArray = Object.entries(res.door_locks);
        const doorLockAllArray = resArray.map(([key, value]) => {
          return {
            key: key,
            //value: value,
            room_id: value.room_id,
            vender: value.vender,
            master_password: value.master_password,
            volume: value.volume,
            otp_protocol_version: value.otp_protocol_version,
            qr_protocol_version: value.qr_protocol_version,
            rf_protocol_version: value.rf_protocol_version,
          };
        });
        setDoorLockAllList(doorLockAllArray);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const columns = [
    {
      title: "No.",
      render: (text, record, index) => index + 1,
      width: "10%",
      align: "center",
    },
    {
      title: "설치객실",
      dataIndex: "room_id",
      key: "room_id",
      render: (text, record, index) => (
        <Select
          showSearch
          placeholder="선택해주세요"
          options={codeRoomList}
          value={record.room_id}
          optionFilterProp="label"
          onChange={(value) => debouncedInputChange(record.key, "room_id", value)}
          className="w-full mr-32"
        />
      ),
      sorter: (a, b) => {
        const roomA = roomAllList.find((rooms) => rooms.key === a.room_id);
        const roomB = roomAllList.find((rooms) => rooms.key === b.room_id);
        if (roomA && roomB) {
          return roomA.no.localeCompare(roomB.no);
        }
        return 0; // 같은 경우
      },
      align: "center",
    },
    {
      title: "제조사",
      dataIndex: "vender",
      key: "vender",
      width: "10%",
      align: "center",
      render: (_, record) => (
        <Select
          placeholder="선택하세요"
          defaultValue={record.vender}
          style={{ width: "100%", textAlign: "left" }}
          onChange={(key, value) => debouncedInputChange(record.key, "vender", value) }
        >
          <Select.Option key="ICREW" value="ICREW">ICREW</Select.Option>
        </Select>
      ),
    },
    {
      title: "비밀번호",
      dataIndex: "master_password",
      key: "master_password",
      //render: (_, record) => record.value.master_password,
      width: "15%",
      align: "center",
      render: (_, record) => {
        return (
          <Input
            defaultValue={record.master_password}
            onChange={(e) => debouncedInputChange(record.key, "master_password", e.target.value)}
          />
        );
      },
    },
    {
      title: "볼륨",
      dataIndex: "volume",
      key: "volume",
      width: "10%",
      align: "center",
      render: (_, record) => {
        return (
          <InputNumber
            min={0}
            defaultValue={record.volume}
            onChange={(value) => debouncedInputChange(record.key, "volume", value)}
          />
        );
      },
    },
    {
      title: "OPT버전",
      dataIndex: "otp_protocol_version",
      key: "otp_protocol_version",
      //render: (_, record) => record.value.otp_protocol_version,
      width: "10%",
      align: "center",
      render: (_, record) => (
        <Select
          placeholder="선택하세요"
          defaultValue={record.otp_protocol_version}
          style={{ width: "100%", textAlign: "left" }}
          onChange={(key, value) => debouncedInputChange(record.key, "otp_protocol_version", value) }
        >
          <Select.Option key="UNUSE" value="UNUSE">UNUSE</Select.Option>
          <Select.Option key="V1" value="V1">V1</Select.Option>
        </Select>
      ),
    },
    {
      title: "QR버전",
      dataIndex: "qr_protocol_version",
      key: "qr_protocol_version",
      //render: (_, record) => record.value.qr_protocol_version,
      width: "10%",
      align: "center",
      render: (_, record) => (
        <Select
          placeholder="선택하세요"
          defaultValue={record.qr_protocol_version}
          style={{ width: "100%", textAlign: "left" }}
          onChange={(key, value) => debouncedInputChange(record.key, "qr_protocol_version", value) }
        >
          <Select.Option key="UNUSE" value="UNUSE">UNUSE</Select.Option>
          <Select.Option key="V1" value="V1">V1</Select.Option>
        </Select>
      ),
    },
    {
      title: "RF버전",
      dataIndex: "rf_protocol_version",
      key: "rf_protocol_version",
      //render: (_, record) => record.value.rf_protocol_version,
      width: "10%",
      align: "center",
      render: (_, record) => (
        <Select
          placeholder="선택하세요"
          defaultValue={record.rf_protocol_version}
          style={{ width: "100%", textAlign: "left" }}
          onChange={(key, value) => debouncedInputChange(record.key, "rf_protocol_version", value) }
        >
          <Select.Option key="UNUSE" value="UNUSE">UNUSE</Select.Option>
          <Select.Option key="V1" value="V1">V1</Select.Option>
        </Select>
      ),
    },
    {
      title: "삭제",
      key: "삭제",
      render: (_, record) => <Button type="primary" danger size="small" onClick={() => handleDelete(record)}>삭제</Button>,
      width: "10%",
      align: "center",
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setPagination(pagination);
  };

  const addRow = (
    key,
    room_id,
    vender,
    master_password,
    volume,
    otp_protocol_version,
    qr_protocol_version,
    rf_protocol_version
  ) => {
    setDoorLockAllList((inputRows) => [
      ...inputRows,
      {
        key: key,
        room_id: room_id,
        vender: vender,
        master_password: master_password,
        volume: volume,
        otp_protocol_version: otp_protocol_version,
        qr_protocol_version: qr_protocol_version,
        rf_protocol_version: rf_protocol_version
      },
    ]);
  };

  const addDooorLock = async (roomId) =>{
    let param = {
      "room_id": roomId,
      "vender": "ICREW",
      "master_password": generateRandomString(8),
      "volume": 1,
      "otp_protocol_version": "V1",
      "qr_protocol_version": "V1",
      "rf_protocol_version": "V1",
    };
    
    try {
      const res = await doorLockInsertApi(param);
      console.log(res);
      if (Object.entries(res.door_locks).length > 0) {
        message.info("추가되었습니다.");
        addRow(
          Object.entries(res.door_locks)[0][0], 
          Object.entries(res.door_locks)[0][1].room_id, 
          Object.entries(res.door_locks)[0][1].vender, 
          Object.entries(res.door_locks)[0][1].master_password, 
          Object.entries(res.door_locks)[0][1].volume, 
          Object.entries(res.door_locks)[0][1].otp_protocol_version, 
          Object.entries(res.door_locks)[0][1].qr_protocol_version, 
          Object.entries(res.door_locks)[0][1].rf_protocol_version);
        console.log(doorLockAllList);
      } else {
        message.error("작업중 문제가 발생했습니다. 다시 시도해주세요.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleNew = () => {
    addDooorLock("");
  };

  const handleRoomsNew = async () => {
    const map2 = new Map(doorLockAllList.map(item => [item.room_id, item]));
    const uniqueFromArr1  = codeRoomList.filter(item => !map2.has(item.value))

    uniqueFromArr1.map((item) => {
      console.log(item);
      addDooorLock(item.value);
    })
  };

  const handleDelete = (data) => {
    console.log(`삭제된 ID: ${data.key}`);
    let room = codeRoomList.find((rooms) => rooms.value === data.room_id);

    Modal.confirm({
      title: `[${room?.label}] 도어락을 삭제하시겠습니까?`,
      width: "440px",
      icon: (<AlertOutlined style={{ transform: "scale(1.3)", color: "#a46bff" }} />),
      okText: "예",
      cancelText: "아니오",
      centered: true,
      onOk: async () => {
        const res = await doorLockDeleteApi(data.key);
        if(data.key === Object.entries(res.door_locks)[0][0]){
          message.info("삭제되었습니다.");
          setDoorLockAllList((prevList) => prevList.filter((item) => item.key !== data.key));
          setEditRows((prevList) => prevList.filter((item) => item.key !== data.key));
        } else {
          message.error("작업중 문제가 발생했습니다. 다시 시도해주세요.");
        }
      },
      onCancel() { },
    });
  };

  const debouncedInputChange = useCallback(
    debounce((key, column, value) => {
      setDoorLockAllList((prevList) =>
        prevList.map((item) => {
          //item.key === key ? { ...item, [column]: value } : item
          if (item.key === key) {
            const updatedItem = { ...item, [column]: value };

            setEditRows((prevList) =>{
              const isAlreadyModified = prevList.find((data) => data.key === key);
              if(isAlreadyModified){
                return prevList.map((data) => data.key === key ? updatedItem : data );
              }else{
                return [...prevList, updatedItem];
              }
            });
            return updatedItem;
          }
          return item;
        })
      );
    }, 300),
    []
  );

  const handleSave = () => {
    console.log(editRows);
    editRows.map( async (data) => {
      let param = {
        "room_id": data.room_id,
        "vender": data.vender,
        "master_password": data.master_password,
        "volume": data.volume,
        "otp_protocol_version": data.otp_protocol_version,
        "qr_protocol_version": data.qr_protocol_version,
        "rf_protocol_version": data.rf_protocol_version,
      };
      console.log(param);

      try {
        const res = await doorLockUpdateApi(data.key, param);
        console.log(res);
        if (data.key === Object.entries(res.door_locks)[0][0]) {
          message.info("저장되었습니다.");
          //setRefresh(true);
        } else {
          message.error("저장중 문제가 발생했습니다. 다시 저장해주세요.");
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleCancel = () => {
    //setRefresh(true);
    window.location.reload();
  }

  return (
    <>
      <div className="p-16 pb-0">
        <h2 className="mb-16">도어락 정보 관리</h2>

        <ConfigProvider locale={koKR}>
          <div className="table-wrap-antd">
            <Table
              columns={columns}
              dataSource={doorLockAllList}
              onChange={handleChange}
              pagination={false}
              rowKey="key"
              scroll={{ y: "32.5rem" }}
              className="ant-table-respons"
            />
          </div>
        </ConfigProvider>
      </div>
      <Divider />
      <div className="p-16 pt-0">
        <div className="flex-row flex-wrap gap-8 mt-16 mb-8">
          <div className="btn-group m-auto">
            <Button type="default" htmlType="button" onClick={handleNew}>
              추가
            </Button>
            <Button type="default" htmlType="button" onClick={handleRoomsNew}>
              모든 객실에 추가
            </Button>
            <Button type="primary" htmlType="button" onClick={handleSave}>
              저장
            </Button>
            <Button type="default" htmlType="button" onClick={handleCancel}>
              취소
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
