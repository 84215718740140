import React, { useEffect, useState } from "react";
import * as XLSX from 'xlsx';
import dayjs from "dayjs";
import koKR from "antd/lib/locale/ko_KR";
import { roomStateLogApi_StartDate } from "../api/api";
import { useCode } from "../login/CodeContext";
import { Button, ConfigProvider, DatePicker, Table, Select } from "antd";
import { disabledDate, disabledTime, formatNumber, DateFormatterSymbol, TimeFormatterSymbol, } from "../util";

export const CheckAdmissionHistory = () => {
  const containType = [
    { label: "전체", value: "" },
    /*
    {label: "키", value: ""},
    {label: "통신", value: ""},
    {label: "문", value: ""},
    {label: "설정온도", value: ""},
    {label: "메인전원", value: ""},
    {label: "입실", value: ""},
    {label: "차량호출", value: ""},
    {label: "입실취소", value: ""},*/
    { label: "객실이동", value: "room_sale_id" },
    { label: "전원차단", value: "power_down_request" },
    { label: "청소", value: "clean_order" },
    { label: "외출", value: "outing" },
    { label: "객실클릭", value: "is_interrupt" },
  ];

  const [roomInfoStateLogList, setRoomInfoStateLogList] = useState([]);
  const [columns, setColumns] = useState([]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({ current: 1, pageSize: 15 });

  const [startDateTime, setStartDateTime] = useState(dayjs(dayjs().format("YYYY-MM-DD") + " 23:59:59"));
  const [containsKey, setContainsKey] = useState("");

  // 공통 코드 불러오는 부분
  const { coderead } = useCode();
  const code = coderead();
  const codeRoom = Object.entries(code.room);
  const codeRoomList = codeRoom.map(([key, value]) => {
    return {
      key: key,
      value: value.display_name,
      type: value.room_type_id,
    };
  });
  const codeRoomType = Object.entries(code.roomType);
  const codeRoomTypeList = codeRoomType.map(([key, value]) => {
    return {
      key: key,
      value: value.display_name,
    };
  });
  const codeStayType = Object.entries(code.stayType);
  const codeStayTypeList = codeStayType.map(([key, value]) => {
    return {
      key: key,
      value: value,
    };
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        //const res = await roomStateLogApi(startDateTime.valueOf(), endDateTime.valueOf());
        const res = await roomStateLogApi_StartDate(startDateTime.valueOf(), containsKey);

        const resArray = Object.entries(res.room_state_logs);
        const roomInfoStateLogArray = resArray.map(([key, value]) => {
          return {
            key: key,
            value: value,
            saleId: value.sale_id,
            roomId: value.room_id,
            roomReserveId: value.room_reserve_id,
            roomTypeId: value.room_type_id,
            stateLogDate: value.registed_time,
            stateLogDateFormat: dayjs(value.registed_time).format("YYYY-MM-DD"),
            stateLogTimeFormat: dayjs(value.registed_time).format("HH:mm:ss"),
          };
        });
        //console.log("roomInfoStateLogArray ==>", roomInfoStateLogArray);
        setRoomInfoStateLogList(roomInfoStateLogArray);

        // 날짜 필터
        const filteredDate = [
          ...new Set(roomInfoStateLogList.map((item) => item.stateLogDateFormat)),
        ].map((date) => ({
          text: date,
          value: date,
        }));

        setColumns([
          {
            title: "날짜",
            dataIndex: "stateLogDate",
            key: "stateLogDate",
            width: "20%",
            align: "center",
            filterSearch: true,
            filters: filteredDate,
            filteredValue: filteredInfo.stateLogDate || null,
            onFilter: (value, record) => record.stateLogDateFormat.includes(value),
            sorter: (a, b) =>
              a.stateLogDateFormat.localeCompare(b.stateLogDateFormat),
            sortOrder:
              sortedInfo.columnKey === "stateLogDate" ? sortedInfo.order : null,
            ellipsis: true,
            render: (text, record, index) => {
              return <DateFormatterSymbol dateTime={record.stateLogDate} />;
            },
          },
          {
            title: "시간",
            dataIndex: "stateLogTime",
            key: "stateLogTime",
            width: "20%",
            align: "center",
            sorter: (a, b) =>
              a.stateLogTimeFormat.localeCompare(b.stateLogTimeFormat),
            sortOrder:
              sortedInfo.columnKey === "stateLogTime" ? sortedInfo.order : null,
            ellipsis: true,
            render: (text, record, index) => {
              return <TimeFormatterSymbol dateTime={record.stateLogDate} />;
            },
          },
          {
            title: "객실명",
            dataIndex: "name",
            key: "name",
            width: "15%",
            align: "center",
            ellipsis: true,
            render: (_, record) => {
              return (
                <>
                  {codeRoomList?.find((room) => room.key === record.roomId)?.value || ""}
                </>
              );
            },
          },
          {
            title: "객실유형",
            dataIndex: "type",
            key: "type",
            width: "15%",
            align: "center",
            ellipsis: true,
            render: (_, record) => {
              return (
                <>
                  {codeRoomTypeList?.find((roomType) => roomType.key === (codeRoomList?.find((room) => room.key === record.roomId)?.type || ""))?.value || ""}
                </>
              );
            },
          },
          {
            title: "상태변경내용",
            dataIndex: "state",
            key: "state",
            align: "center",
            ellipsis: true,
            render: (_, record) => {
              //console.log(record.value.changed_datas);
              if (Object.keys(record.value.changed_datas).includes("is_interrupt")) {
                return "객실 클릭";
              } else if (Object.keys(record.value.changed_datas).includes("clean_order")) {
                let add_msg = "";
                if (Object.keys(record.value.changed_datas).includes("stay_type")) {
                  add_msg = record.value.changed_datas.stay_type === null ? " (퇴실 -> 공실)" : "";
                }
                return ((record.value.changed_datas.clean_order ? "청소요청" : "청소완료") + add_msg);
              } else if (Object.keys(record.value.changed_datas).includes("outing")) {
                return "외출";
              } else if (Object.keys(record.value.changed_datas).includes("stay_type")) {
                return (
                  codeStayTypeList?.find((code) => code.key === record.value.changed_datas.stay_type)?.value || ""
                );
              } else if (Object.keys(record.value.changed_datas).includes("power_down_request")) {
                return record.value.changed_datas.power_down_request ? "전원강제차단" : "";
              } else if (Object.keys(record.value.changed_datas).includes("inspect_order")) {
                return record.value.changed_datas.inspect_order ? "점검요청" : "점검완료";
              } else if (Object.keys(record.value.changed_datas).includes("room_sale_id")) {
                return "객실이동";
              } else {
                console.log("changed_datas ==> ", record.value.changed_datas);
                return "변경내용 미설정";
              }
            },
          },
        ]);

        // console.log("columns");
        // console.log(columns);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  //}, [startDateTime, endDateTime, filteredInfo, sortedInfo]);
  }, [startDateTime, containsKey, filteredInfo, sortedInfo]);

  // 달력
  const onChangeStartDatePicker = (date, dateString) => {
    if (dateString) {
      //const endDateString = dateString + " 23:59:59";
      const startDateString = dateString + " 23:59:59";
      
      //setEndDateTime(dayjs(endDateString));
      setStartDateTime(dayjs(startDateString));
    }
  };

  const onChangeContainsKey = (value) => {
    setContainsKey(value);
  };

  // 테이블 컬럼 생성
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setPagination(pagination);
  };
  // 필터 초기화 버튼
  const clearFilters = () => {
    setFilteredInfo({});
  };
  // 모두 초기화 버튼
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setPagination({ current: 1, pageSize: 15 });
  };
  // 날짜별 정렬 버튼
  const setPaymentDate = () => {
    setSortedInfo({
      order: "descend",
      columnKey: "payment_date",
    });
  };

  return (
    <div className="p-16 pb-0">
      <h2 id="page_title">입실 내역 조회</h2>

      <ConfigProvider locale={koKR}>
        <div className="flex-row flex-wrap gap-8 mt-16 mb-8">
          {/* <DatePicker
            showTime
            onChange={onChangeDatePicker}
            defaultValue={dayjs()}
            format="YYYY-MM-DD HH:mm:ss"
            style={{ minWidth: "8.75rem" }}
            placement={"bottomLeft"}
            disabledDate={disabledDate}
            disabledTime={disabledTime}
          /> */}

          <DatePicker
            //showTime
            onChange={onChangeStartDatePicker}
            defaultValue={dayjs(startDateTime)}
            format="YYYY-MM-DD"
            style={{ minWidth: "8.75rem" }}
            placement={"bottomLeft"}
          />
          <Select
            placeholder="상태필터"
            options={containType}
            style={{
              width: "100%",
              minWidth: "100px",
              maxWidth: "160px",
              textAlign: "left",
              flex: 1,
            }}
            onChange={onChangeContainsKey}
          ></Select>
          {/* <span> ~ </span>
          <DatePicker
            //showTime
            onChange={onChangeEndDatePicker}
            defaultValue={dayjs(endDateTime)}
            format="YYYY-MM-DD"
            style={{ minWidth: "8.75rem" }}
            placement={"bottomLeft"}
          /> 

          <div className="btn-group ml-auto">
            <Button onClick={setPaymentDate}>날짜별 정렬</Button>
            <Button onClick={clearFilters}>전체 필터초기화</Button>
            <Button onClick={clearAll}>모두 초기화</Button>
          </div>*/}
        </div>

        <div className="table-wrap-antd">
          <Table
            columns={columns}
            dataSource={roomInfoStateLogList}
            onChange={handleChange}
            pagination={pagination}
            rowKey="key"
            // scroll={{ y: "28.8rem", x: "2600px" }}
            scroll={{ y: "27.8rem", x: "max-content" }}
            className="ant-table-respons"
            id={"mainTable"}
          />
        </div>
      </ConfigProvider>
    </div>
  );
};
