import "./App.css";
import "../src/fonts/font.css";
import { Container } from "./commonComp/Container";
import { Button, ConfigProvider } from "antd";
import { DataTest } from "./api/DataTest";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Z_NotFoundSample } from "./commonComp/Z_NotFoundSample";
import { Login } from "./login/Login";
import { useAuth } from "./login/AuthContext";
import { WechatOutlined } from "@ant-design/icons";
import { ReserveMain } from "./menuReserve/ReserveMain";
import { SettingMain } from "./menuSetting/SettingMain";
import { ManageMain } from "./menuManage/ManageMain";
import { ListMain } from "./menuList/ListMain";
import { EditMain } from "./menuEdit/EditMain";
import { DashbordMain } from "./menuDashbord/DashbordMain";

function App() {
  const { user } = useAuth();

  return (
    <BrowserRouter>
      <div className="App">
        <ConfigProvider
          theme={{
            token: {
              // Seed Token
              // colorPrimary: "#00b96b",
              colorPrimary: "#7c3ef2",
              borderRadius: 4,

              // Alias Token
              // colorBgContainer: "#f6ffed",
              colorBgContainer: "#f9f9f9",
            },
          }}
        >
          {user === null ? (
            <Login user={user} />
          ) : (
            <Routes>
              <Route path="/" element={<Container user={user}/>}>
                <Route path="edit" element={<EditMain />}></Route>
                <Route path="list">
                  <Route path="salesinquiry" element={<ListMain currentMenuKey="SalesInquiry"/>} />
                  <Route path="admissionhistory" element={<ListMain currentMenuKey="CheckAdmissionHistory"/>} />
                  <Route path="roomhistory" element={<ListMain currentMenuKey="CheckRoomHistory"/>} />
                  <Route path="mileage" element={<ListMain currentMenuKey="MileageMemberInquiry"/>} />
                </Route>
                <Route path="manage">
                  <Route path="roominfo" element={<ManageMain currentMenuKey="RoomInfoManage"/>} />
                  <Route path="roomtype" element={<ManageMain currentMenuKey="RoomTypeManage"/>} />
                  <Route path="doorlockinfo" element={<ManageMain currentMenuKey="DoorlockInfoManage"/>} />
                  <Route path="qrtext" element={<ManageMain currentMenuKey="QRTextManage"/>} />
                </Route>
                <Route path="setting">
                  <Route path="ratetime" element={<SettingMain currentMenuKey="SettingRateTime"/>} />
                  <Route path="operaterule" element={<SettingMain currentMenuKey="SettingOperateRule"/>} />
                  <Route path="screen" element={<SettingMain currentMenuKey="SettingScreen"/>} />
                  <Route path="reservation" element={<SettingMain currentMenuKey="SettingReservation"/>} />
                  <Route path="sms" element={<SettingMain currentMenuKey="SettingSMS"/>} />
                </Route>
                <Route path="reserve" element={<ReserveMain />}></Route>
                <Route path="dashbord" element={<DashbordMain />}></Route>
                <Route path="*" element={<Z_NotFoundSample />}></Route>
              </Route>
            </Routes>
          )}

          {/* <Button className="btn-chat-icon">
            <WechatOutlined />
          </Button> */}
        </ConfigProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
