import {
  WifiOutlined,
  PoweroffOutlined,
  MoreOutlined,
  AlertOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  message,
  Modal,
  Dropdown,
  Space
} from "antd";
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import dayjs from "dayjs";
import {
  handleInputNumberKeyDown,
  handleInputNumberOnInput,
  formatNumber,
  disabledDate,
  disabledBeforeDate,
  disabledDateThreeMonthsAgo,
  disabledTime,
} from "../util";
import { useCode } from "../login/CodeContext";
import {
  roomReserveSelectOneApi,
  roomSaleInfoOnlyApi,
  roomSaleUpdateApi,
  roomSaleInsertApi,
  roomTypeInfoOnlyApi,
  roomReserveAllByAccomSearchApi,
  roomUpdateApi,
  roomSaleDeleteApi
} from "../api/api";
import { ModalRoomSelecter } from "./ModalRoomSelecter";

export const ModalTab1 = forwardRef(({ rooms, stayType, roomReserveAllList, refreshRoom }, ref) => {

  // 모바일인지 여부를 판단하는 상태 추가
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // 초기화 및 리사이즈 이벤트 핸들러 설정
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 870); // 768px 이하이면 모바일로 판단
    };

    handleResize(); // 초기 상태 설정
    window.addEventListener("resize", handleResize); // 윈도우 크기 변경 이벤트 감지

    return () => {
      window.removeEventListener("resize", handleResize); // 컴포넌트 언마운트 시 이벤트 제거
    };
  }, []);

  // 변수
  const [checkInTime, setCheckInTime] = useState(dayjs());    //체크인타임
  const [checkOutTime, setCheckOutTime] = useState(dayjs());  //체크아웃타임
  const [personCount, setPersonCount] = useState(1);          //숙박인원
  const [phones, setPhones] = useState(""); //전화번호
  const [carNo, setCarNo] = useState(""); //차량번호
  const [fee, setFee] = useState(0); //객실요금
  const [change, setChange] = useState(0); //미수금
  const [curStayType, setCurStayType] = useState(stayType === "" ? rooms.value.stay_type : stayType); //투숙형태
  const [note, setNote] = useState(""); //메모
  const [inputRows, setInputRows] = useState([]);
  const [refreshModal1, setRefreshModal1] = useState(false);

  // 공통 코드 불러오는 부분
  const { coderead } = useCode();
  const code = coderead();
  const codeRoomType = Object.entries(code.roomType);
  const codeRoomTypeList = codeRoomType.map(([key, value]) => {
    return { key: key, value: value.display_name };
  });
  const codeAgentType = Object.entries(code.agentType);
  const codeAgentTypeList = codeAgentType.map(([key, value]) => {
    return { key: key, value: value };
  });
  const codeAgentTypeList_selectbox = codeAgentType.map(([key, value]) => {
    return { value: key, label: value };
  });
  const codeStayType = Object.entries(code.stayType);
  const codeStayTypeList = codeStayType.map(([key, value]) => {
    return { key: key, value: value };
  });

  // 데이터 불러오는 부분
  const [roomSaleArr, setRoomSaleArr] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await roomSaleInfoOnlyApi(rooms.value.room_sale_id);
        const resArray = Object.entries(res.room_sales);
        setRoomSaleArr(resArray[0][1]);
        setInputRows([]);
        resArray[0][1].payments.map((pay) => {
          if (pay.type !== "") {
            addInput(
              pay.type,
              pay.payment_date,
              pay.account_no,
              pay.accepted_cash,
              pay.changed_cash,
              pay.amount_paid_creadit_card,
              pay.credit_card_no,
              pay.credit_card_accepter_name,
              pay.credit_card_approval_no,
              pay.agent_type,
              pay.amount_paid_agent
            );
          }
        });
        setCurStayType(resArray[0][1].stay_type);
        setCheckInTime(resArray[0][1].check_in_time);
        setCheckOutTime(resArray[0][1].check_out_sched_time);
        setPhones((resArray[0][1].phones).join(''));
        setPersonCount(resArray[0][1].person_count);
        setCarNo(resArray[0][1].car_no);
        setFee(resArray[0][1].fee);
        setNote(resArray[0][1].note)
      } catch (error) {
        console.log(error);
      }
    };
    if(rooms.value.room_sale_id !== null){
      fetchData();
    }else{
      setCurStayType("");
      setCheckInTime(dayjs());
      setCheckOutTime(dayjs());
      setPhones("");
      setPersonCount(0)
      setCarNo("");
      setNote("");
      //setFee(resArray[0][1].fee);
      setInputRows([]);
    }
  }, [rooms.value.room_sale_id]);

  const [reveList, setReveList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await roomReserveAllByAccomSearchApi();
        const resArray = Object.entries(res.room_reserves);
        console.log("reve list ==> ", resArray);
        const reveAllArray = [];
        resArray.map((reve) => {
          if((reve[1].room_type_id === rooms.value.room_type_id)){ // && reve[1].state !== "USING"
            reveAllArray.push({ key: reve[0], value: reve[1] });
          }
        });
        setReveList(reveAllArray);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const [roomTypeMap, setRoomTypeMap] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await roomTypeInfoOnlyApi(rooms.value.room_type_id);
        const resArray = Object.entries(res.room_types);
        setRoomTypeMap(resArray[0][1]);
        console.log("roomTypeMap ==>", resArray[0][1]);
        
        if(rooms.value.room_sale_id == null){
          setPersonCount(resArray[0][1].basic_capacity)
        }
        setTypePrice(resArray[0][1], curStayType);
      } catch (error) {
        console.log(error);
      }
    };
    if(rooms.value.room_type_id !== null){
      fetchData();
    }
  }, [rooms.value.room_type_id]);

  useEffect(() => {
    let add = 0;
    inputRows.map((input) => {
      add += input.accepted_cash + input.amount_paid_creadit_card + input.amount_paid_agent - input.changed_cash;
    });
    for (let i = 0; i < inputRows.length; i++) {
      console.log(inputRows[i]);
    }
    setChange(fee - add);
  }, [inputRows]);

  // 투숙인원
  const onChangeInputNumberPersonCount = (value) => {
    setPersonCount(value);
  };
  //객실요금
  const onChangeInputNumber2 = (value) => {
    setFee(value);
  };

  const onChangeInputNumber3 = (value) => {
    console.log("선결제액", value);
  };

  // 달력
  const dateFormat = "YYYY-MM-DD";
  const onChangeDatePickerCheckIn = (date, dateString) => {
    setCheckInTime(dayjs(dateString));
  };
  const onChangeDatePickerCheckOut = (date, dateString) => {
    setCheckOutTime(dayjs(dateString));
  };

  // select
  const onChangeSelect1 = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearchSelect1 = (value) => {
    console.log("search:", value);
  };

  // checkbox
  const onChangeCheckBox1 = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  // switch
  const onChangeSwitch1 = (checked) => {
    console.log(`switch to ${checked}`);
  };

  // radio
  const onChangeRadio_stayType = (e) => {
    console.log(`투숙형태:${e.target.value}`);
    setCurStayType(e.target.value);
    
    setTypePrice(roomTypeMap, e.target.value);
  };

  const setTypePrice = (arr, type) => {
    console.log("type==>", type);
    if(rooms.value.room_sale_id === null){
      if(type === "DAYS"){
        const weekprc = arr.days_pricing;
        

        weekprc.map((prc) => {
          if(prc.use_front_manager && prc.day_of_week[0].substr(0, 3) === dayjs(checkInTime).format('ddd').toUpperCase()){
            console.log("prc 요일 ==>", prc.day_of_week[0].substr(0, 3));
            console.log("prc 요일 ==>", dayjs(checkInTime).format('ddd').toUpperCase());
            
            setFee(prc.basic_price);
            console.log("prc ==>",prc);
          }
        });
      }
      if(type === "HOURS" || type === "LONG_DAYS"){
        const hourprc = arr.times_pricing;
        
        let checkinDate = dayjs(checkInTime).format("YYYY-MM-DD");
        hourprc.map((prc) => {
          if(prc.use_front_manager 
            && prc.day_of_week[0].substr(0, 3) === dayjs(checkInTime).format('ddd').toUpperCase()
            && dayjs(checkinDate + " " + prc.sellable_time_range[0].start_at + ":00").valueOf() < dayjs(checkInTime).valueOf()
            && dayjs(checkInTime).valueOf() < dayjs(checkinDate + " " + prc.sellable_time_range[0].end_before + ":00").valueOf()
          ){
            console.log("prc 요일 ==>", prc.day_of_week[0].substr(0, 3));
            
            setFee(prc.basic_price);
            console.log("prc ==>",prc);
          }
        });
      }
      if(type === "OTHER"){
        setFee(0);
      }
    }
  };

  // Input : 전화번호
  const handleInputChange_Phones = (e) => {
    setPhones(e.target.value);
  };

  // Input : 차량번호
  const handleInputChange = (e) => {
    setCarNo(e.target.value);
  };

  // Input : 객실표시
  const handleInputChange_Note = (e) => {
    setNote(e.target.value);
  };

  // 시간 변환 : ex) 09-30 15:00
  const DateTimeFormatterSimple = ({ dateTime }) => {
    const date = new Date(Number(dateTime));
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      weekday: "long",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    const formattedDate = new Intl.DateTimeFormat("ko-KR", options).format(date);

    const part1 = formattedDate.split(". "); // 날짜 분리
    const [time] = part1[3].split(" ").slice(-1);
    const [hour, minute] = time.split(":");

    return `${part1[1]}-${part1[2]} ${hour}:${minute}`;
  };

  // 예약 - selectBox
  const [selectedRes, setSelectedRes] = useState(null);
  const handleChange2 = (value) => {
    setSelectedRes(value);
    console.log(`selected ${value}`);
    
    //console.log("reveList ",reveList);
    setInputRows([]);
    reveList.map((reve) => {
      if(reve.key === value){
        console.log(reve);
        setCurStayType(reve.value.stay_type)
        setCheckInTime(reve.value.check_in_sched);
        setCheckInTime(reve.value.check_in_sched);
        setCheckOutTime(reve.value.check_out_sched);
        setPhones(reve.value.phone);
        setNote(reve.value.memo);
        setFee(reve.value.fee);
        if(reve.value.prepaid > 0){
          addInput("AGENT", Date.now(), "", 0, 0, 0, "", "", "", reve.value.agent, reve.value.prepaid)
        }
      }
    });
  };

  // 동적 입력 행 추가 및 삭제
  const [activeForm, setActiveForm] = useState(null);
  const addInput = (
    type,
    data,
    acct,
    pay_cash,
    changed_cash,
    pay_card,
    cardno,
    card_acct,
    card_apprno,
    agent,
    pay_agent
  ) => {
    setActiveForm(type);
    setInputRows((inputRows) => [
      ...inputRows,
      {
        id: Math.floor(Math.random() * 1000000) + 1,
        type: type,
        payment_date: data,
        account_no: acct,
        accepted_cash: pay_cash,
        changed_cash: changed_cash,
        amount_paid_creadit_card: pay_card,
        credit_card_no: cardno,
        credit_card_accepter_name: card_acct,
        credit_card_approval_no: card_apprno,
        agent_type: agent,
        amount_paid_agent: pay_agent,
      },
    ]);
  };

  const removeInput = (id) => {
    if (inputRows.length > 0) {
      setInputRows(inputRows.filter((input) => input.id !== id));
    }
  };

  // 계좌번호 input
  const handleChangeAccount = (id, account_no) => {
    setInputRows(
      inputRows.map((input) =>
        input.id === id ? { ...input, account_no } : input
      )
    );
  };
  // 신용카드 금액 inputNumber
  const handleChangeAmountPaidCreaditCard = (id, amount_paid_creadit_card) => {
    setInputRows(
      inputRows.map((input) =>
        input.id === id ? { ...input, amount_paid_creadit_card } : input
      )
    );
  };

  // 신용카드 승인번호
  const handleChangeCreditCardApprovalNo = (id, credit_card_approval_no) => {
    setInputRows(
      inputRows.map((input) =>
        input.id === id ? { ...input, credit_card_approval_no } : input
      )
    );
  };
  
  // 받은금액
  const handleChangeAcceptedCash = (id, accepted_cash) => {
    setInputRows(
      inputRows.map((input) =>
        input.id === id ? { ...input, accepted_cash } : input
      )
    );
  };
  // 거스름돈
  const handleChangeChangedCash = (id, changed_cash) => {
    setInputRows(
      inputRows.map((input) =>
        input.id === id ? { ...input, changed_cash } : input
      )
    );
  };
  // 대행사 결제금액
  const handleChangeAmountPaidAgent = (id, amount_paid_agent) => {
    setInputRows(
      inputRows.map((input) =>
        input.id === id ? { ...input, amount_paid_agent } : input
      )
    );
  };
  // 결제일자 datePicker
  const handleChangePayDateValue = (id, date) => {
    const payment_date = date.format("YYYY-MM-DD");
    setInputRows(
      inputRows.map((input) =>
        input.id === id ? { ...input, payment_date } : input
      )
    );
  };
  // 대행사 selectbox
  const handleChangeAgency = (id, agent_type) => {
    setInputRows(
      inputRows.map((input) =>
        input.id === id ? { ...input, agent_type } : input
      )
    );
  };
  // 카드사 selectbox
  const handleChangeCreditCardAccepterName = (
    id,
    credit_card_accepter_name
  ) => {
    setInputRows(
      inputRows.map((input) =>
        input.id === id ? { ...input, credit_card_accepter_name } : input
      )
    );
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    WillBeUsedInParentComponent,
  }));

  const WillBeUsedInParentComponent = async () => {
    if(phones !== ""){
      if(phones.length < 4){ message.error("전화번호를 확인해주세요."); return false;}
    }
    if(dayjs(checkInTime).valueOf() > dayjs(checkOutTime).valueOf()){ message.error("입실시간, 퇴실 예정일시를 확인해주세요."); return false;}

    const param = {
      room_id: rooms.key,
      //ignore_power_down: true,
      activate: true,
      room_reserve_id: selectedRes,
      check_in_time: dayjs(checkInTime).valueOf(),
      check_out_sched_time: dayjs(checkOutTime).valueOf(),
      person_count: personCount,    // parseInt(document.getElementById("person_count").value),
      alarm: null,
      fee: parseInt(document.getElementById("fee").value.replace(/,/g, "")),
      //"channel_type": "APP",
      //"channel_id": "string",
      stay_type: curStayType,
      car_no: carNo,
      note: note,
      payments: inputRows,
    };
    if(phones!==""){
      param.phones = [phones.substring(0,3), phones.substring(3,7) , phones.substring(7,11)]
    }
    console.log(param);

    try {
      if (rooms.value.room_sale_id !== null) {
        const res = await roomSaleUpdateApi(rooms.value.room_sale_id, param);
        if (rooms.value.room_sale_id === Object.entries(res.room_sales)[0][0]) {
          console.log("person_count : " + param.person_count);
          message.info("저장되었습니다.");
        } else {
          message.error("저장중 문제가 발생했습니다. 다시 저장해주세요.");
        }
      } else {
        const res = await roomSaleInsertApi(param);
        console.log(Object.entries(res.room_sales));
        if (Object.entries(res.room_sales).length > 0) {
          message.info("저장되었습니다.");
        } else {
          message.error("저장중 문제가 발생했습니다. 다시 저장해주세요.");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };


  // 객실��태 드롭다운
  let items = [];
  if (rooms.value.room_sale_id !== null) {
    items.push(
      { key: "OUTING", label: !rooms.value.outing ? "외출" : "외출 복귀" },
      { key: "EMPTY", label: "퇴실" },
      { key: "EMPTY_NO", label: "퇴실(전원차단X)" },
      { key: "REQUEST_STATE_CLEAR", label: "퇴실(청소요청X)", },  //구 공실처리      
    );
  } else {
    items.push(
      { key: "EMPTY_CANCEL", label: "퇴실취소", }
    );
  }
  items.push(
    {
      key: "CLEAN_ORDER",
      label: rooms.value.clean_order ? "청소완료" : "청소요청",
    },
    {
      key: "INSPECT_ORDER",
      label: rooms.value.inspect_order ? "점검완료" : "점검요청",
    },
  );
  if (rooms.value.room_sale_id !== null) {
    items.push(
      { key: "MOVE", label: "객실이동" },
      { key: "SALE_CANCEL", label: "입실취소" }
    );
  }

  const [messageApi, contextHolder] = message.useMessage();
  const onClick = ({ key }) => {
    let selectedLabel = items.find((item) => item.key === key).label;
    switch (key) {
      case "OUTING":
        Modal.confirm({
          title: `[${selectedLabel}] 상태변경 하시겠습니까?`,
          width: "440px",
          icon: (
            <AlertOutlined style={{ transform: "scale(1.3)", color: "#a46bff" }} />
          ),
          okText: "예",
          cancelText: "아니오",
          centered: true,
          onOk: async () => {
            const param = {
              room_type_id: rooms.value.room_type_id,
              outing: !rooms.value.outing,
            };

            try {
              const res = await roomUpdateApi(rooms.key, param);
              console.log(res);
              if (rooms.key === Object.entries(res.rooms)[0][0]) {
                message.info("변경되었습니다.");
                refreshRoom();
              } else {
                message.error(
                  "변경중 문제가 발생했습니다. 잠시후 다시 작업해주세요."
                );
              }
            } catch (error) {
              if(error.response.data){
                message.error("변경중 문제가 발생했습니다. code : " + error.status + " message: " + error.response.data.message);
              }
              console.log(error);
            }
          },
          onCancel() {},
        });
        break;
      case "CLEAN_ORDER":
        Modal.confirm({
          title: `[${selectedLabel}] 상태변경 하시겠습니까?`,
          width: "440px",
          icon: (
            <AlertOutlined style={{ transform: "scale(1.3)", color: "#a46bff" }} />
          ),
          okText: "예",
          cancelText: "아니오",
          centered: true,
          onOk: async () => {
            const param = {
              room_type_id: rooms.value.room_type_id,
              clean_order: !rooms.value.clean_order,
            };

            try {
              const res = await roomUpdateApi(rooms.key, param);
              console.log(res);
              if (rooms.key === Object.entries(res.rooms)[0][0]) {
                message.info("변경되었습니다.");
                refreshRoom();
              } else {
                message.error(
                  "변경중 문제가 발생했습니다. 잠시후 다시 작업해주세요."
                );
              }
            } catch (error) {
              if(error.response.data){
                message.error("변경중 문제가 발생했습니다. code : " + error.status + " message: " + error.response.data.message);
              }
              console.log(error);
            }
          },
          onCancel() {},
        });
        break;
      case "INSPECT_ORDER":
        Modal.confirm({
          title: `[${selectedLabel}] 상태변경 하시겠습니까?`,
          width: "440px",
          icon: (
            <AlertOutlined
              style={{ transform: "scale(1.3)", color: "#a46bff" }}
            />
          ),
          okText: "예",
          cancelText: "아니오",
          centered: true,
          onOk: async () => {
            const param = {
              room_type_id: rooms.value.room_type_id,
              inspect_order: !rooms.value.inspect_order,
            };

            try {
              const res = await roomUpdateApi(rooms.key, param);
              console.log(res);
              if (rooms.key === Object.entries(res.rooms)[0][0]) {
                message.info("변경되었습니다.");
                refreshRoom();
              } else {
                message.error(
                  "변경중 문제가 발생했습니다. 잠시후 다시 작업해주세요."
                );
              }
            } catch (error) {
              if(error.response.data){
                message.error("변경중 문제가 발생했습니다. code : " + error.status + " message: " + error.response.data.message);
              }
              console.log(error);
            }
          },
          onCancel() {},
        });
        break;
      case "REQUEST_STATE_CLEAR":
        Modal.confirm({
          title: `[${selectedLabel}] 상태변경 하시겠습니까?`,
          width: "440px",
          icon: (
            <AlertOutlined
              style={{ transform: "scale(1.3)", color: "#a46bff" }}
            />
          ),
          okText: "예",
          cancelText: "아니오",
          centered: true,
          onOk: async () => {
            const param = {
              room_type_id: rooms.value.room_type_id,
              request_state_clear: !rooms.value.request_state_clear,
            };

            try {
              const res = await roomUpdateApi(rooms.key, param);
              console.log(res);
              if (rooms.key === Object.entries(res.rooms)[0][0]) {
                message.info("변경되었습니다.");
                refreshRoom();
              } else {
                message.error(
                  "변경중 문제가 발생했습니다. 잠시후 다시 작업해주세요."
                );
              }
            } catch (error) {
              if(error.response.data){
                message.error("변경중 문제가 발생했습니다. code : " + error.status + " message: " + error.response.data.message);
              }
              console.log(error);
            }
          },
          onCancel() {},
        });
        break;
      case "EMPTY":
        Modal.confirm({
          title: `[${selectedLabel}] 상태변경 하시겠습니까?`,
          width: "440px",
          icon: (
            <AlertOutlined
              style={{ transform: "scale(1.3)", color: "#a46bff" }}
            />
          ),
          okText: "예",
          cancelText: "아니오",
          centered: true,
          onOk: async () => {
            const param = {
              activate: false,
            };

            try {
              const res = await roomSaleUpdateApi(rooms.value.room_sale_id, param);
              console.log(res);
              if (rooms.value.room_sale_id === Object.entries(res.room_sales)[0][0]) {
                message.info("변경되었습니다.");
                refreshRoom();
              } else {
                message.error(
                  "변경중 문제가 발생했습니다. 잠시후 다시 작업해주세요."
                );
              }
            } catch (error) {
              if(error.response.data){
                message.error("변경중 문제가 발생했습니다. code : " + error.status + " message: " + error.response.data.message);
              }
              console.log(error);
            }
          },
          onCancel() {},
        });
        break;
      case "EMPTY_NO":
        Modal.confirm({
          title: `[${selectedLabel}] 상태변경 하시겠습니까?`,
          width: "440px",
          icon: (
            <AlertOutlined
              style={{ transform: "scale(1.3)", color: "#a46bff" }}
            />
          ),
          okText: "예",
          cancelText: "아니오",
          centered: true,
          onOk: async () => {
            const param = {
              activate: false,
              ignore_power_down: true,
            };

            try {
              const res = await roomSaleUpdateApi(rooms.value.room_sale_id, param);
              console.log(res);
              if (rooms.value.room_sale_id === Object.entries(res.room_sales)[0][0]) {
                message.info("변경되었습니다.");
                refreshRoom();
              } else {
                message.error(
                  "변경중 문제가 발생했습니다. 잠시후 다시 작업해주세요."
                );
              }
            } catch (error) {
              if(error.response.data){
                message.error("변경중 문제가 발생했습니다. code : " + error.status + " message: " + error.response.data.message);
              }
              console.log(error);
            }
          },
          onCancel() {},
        });
        break;
      case "EMPTY_CANCEL":
        Modal.confirm({
          title: `[${selectedLabel}] 상태변경 하시겠습니까?`,
          width: "440px",
          icon: (
            <AlertOutlined
              style={{ transform: "scale(1.3)", color: "#a46bff" }}
            />
          ),
          okText: "예",
          cancelText: "아니오",
          centered: true,
          onOk: async () => {
            const param = {
              activate: true,
            };

            try {
              const res = await roomSaleUpdateApi(rooms.value.previous_room_sale_id, param);
              console.log(res);
              if (rooms.value.previous_room_sale_id === Object.entries(res.room_sales)[0][0]) {
                message.info("변경되었습니다.");
                refreshRoom();
              } else {
                message.error(
                  "변경중 문제가 발생했습니다. 잠시후 다시 작업해주세요."
                );
              }
            } catch (error) {
              if(error.response.data){
                message.error("변경중 문제가 발생했습니다. code : " + error.status + " message: " + error.response.data.message);
              }
              console.log(error);
            }
          },
          onCancel() {},
        });
        break;
      case "MOVE":
        showModalRoomSel(true);
        break;
      case "SALE_CANCEL":
        Modal.confirm({
          title: `[${selectedLabel}] 상태변경 하시겠습니까?`,
          width: "440px",
          icon: (
            <AlertOutlined
              style={{ transform: "scale(1.3)", color: "#a46bff" }}
            />
          ),
          okText: "예",
          cancelText: "아니오",
          centered: true,
          onOk: async () => {
            try {
              const res = await roomSaleDeleteApi(rooms.value.room_sale_id);
              console.log(res);
              if (rooms.value.room_sale_id === Object.entries(res.room_sales)[0][0]) {
                message.info("변경되었습니다.");
                refreshRoom();
              } else {
                message.error(
                  "변경중 문제가 발생했습니다. 잠시후 다시 작업해주세요."
                );
              }
            } catch (error) {
              if(error.response.data){
                message.error("변경중 문제가 발생했습니다. code : " + error.status + " message: " + error.response.data.message);
              }
              console.log(error);
            }
          },
          onCancel() {},
        });
        break;
      default:
        messageApi.info("작업중입니다..");
        break;
    }
  };

   const [isModalOpenRoomSel, setIsModalOpenRoomSel] = useState(false);
   const showModalRoomSel = () => {
     setIsModalOpenRoomSel(true);
   };
   const handleOkModalRoomSel = () => {
   };
   const handleCancelModalRoomSel = () => {
     setIsModalOpenRoomSel(false);
   };

  return (
    
    <form id="saveFrm" onSubmit={WillBeUsedInParentComponent}>
      <div className="flex-col">
      <div className="flex-row flex-wrap gap-16 sec-2 mb-16">
        <div class="text-red"></div>
          <div className="btn-group" style={{ textAlign: 'right' }}>
            <div className="more">
              {contextHolder}
              <Space direction="vertical">
                <Space wrap>
                  <Dropdown
                    menu={{ items, onClick }}
                    trigger={["click"]}
                    overlayClassName="cuzDrop2"
                    placement="bottomLeft"
                  >
                    <Button>객실상태변경<CaretDownOutlined /></Button>
                  </Dropdown>
                </Space>
              </Space>
            </div>
          </div>
        </div>
        {/* 객실명 */}
        <div className="flex-row flex-wrap gap-10 mb-16">
          
          <h3 className="label p-0">객실명 &nbsp;:</h3>
          <h2>{rooms.value.display_name}호 &nbsp;-</h2>
          <h2 className="mr-16">
            {codeRoomTypeList?.find((roomType) => roomType.key === rooms.value.room_type_id)?.value || ""}
          </h2>

          <div className="w-full flex-1">
            {/* <div>room_id : {rooms.key}</div>
            <div>room_type_id : {rooms.value.room_type_id}</div>
            <div>room_sale_id : {rooms.value.room_sale_id}</div>
            <div>room_reserve_id : {rooms.value.room_reserve_id}</div> */}
            <Select
              style={{ width: "100%", minWidth: "17.5rem" }}
              placeholder={reveList.length>0 ? "해당 타입으로 등록된 예약이 " + reveList.length + "건 있습니다" : "해당 타입으로 등록된 예약이 없습니다."}
              onChange={handleChange2}
              //value={selectedRes}
              defaultValue={rooms.value.room_reserve_id}
              disabled={rooms.value.room_reserve_id ? true : false}
            >
              {/* <Select.Option value={null}>없음</Select.Option>  reveAllList */}
              {reveList.map((roomRes, idx) =>
                roomRes.value.room_type_id === rooms.value.room_type_id ? (
                  <Select.Option key={roomRes.key} disabled={roomRes.value.state === "USING" ? true: false}>
                    <div className="flex-row gap-8">
                      <div>
                        [
                        {codeAgentTypeList?.find(
                          (code) => code.key === roomRes.value.agent
                        )?.value || "OTHER"}
                        ]
                      </div>
                      <DateTimeFormatterSimple
                        dateTime={roomRes.value.check_in_sched}
                      />
                      <div>
                        {codeStayTypeList?.find(
                          (code) => code.key === roomRes.value.stay_type
                        )?.value || ""}
                      </div>
                      <div>{roomRes.value.name}</div>
                      <div>{roomRes.value.phone}</div>
                    </div>
                  </Select.Option>
                ) : null
              )}
            </Select>
          </div>
        </div>

        {/* 투숙형태 */}
        <div className="flex-row flex-wrap gap-16 sec-2 mb-16">
          <div className="flex-row">
            <h3 className="label">투숙형태 &nbsp;:</h3>
            <div className="btn-group text-l">
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                className="room-stay-type"
                value={curStayType}
                onChange={onChangeRadio_stayType}
              >
                <Radio name="stay_type" value={"HOURS"} label={"HOURS"}>
                  대실
                </Radio>
                <Radio name="stay_type" value={"DAYS"} label={"DAYS"}>
                  숙박
                </Radio>
                <Radio name="stay_type" value={"LONG_DAYS"} label={"LONG_DAYS"}>
                  장기
                </Radio>
                <Radio name="stay_type" value={"OTHER"} label={"OTHER"}>
                  기타
                </Radio>
              </Radio.Group>
            </div>
          </div>

          <div className="flex-row">
            <h3 className="label">투숙인원 &nbsp;:</h3>
            <InputNumber
              id="person_count"
              min={1}
              max={20}
              value={personCount}
              //key={`roomSale-person-count${idx}`}
              formatter={formatNumber}
              onChange={onChangeInputNumberPersonCount}
              onKeyDown={handleInputNumberKeyDown}
              onInput={handleInputNumberOnInput}
              style={{ minWidth: "5.625rem" }}
            />
          </div>
        </div>

        {/* 입실시간 */}
        {!isMobile ? (
          // 모바일이 아닌 경우
          <div className="flex-row flex-wrap gap-16 sec-2 mb-16">
            <div className="flex-row">
              <h3 className="label">입실시간 &nbsp;:</h3>
              <DatePicker
                showTime
                onChange={onChangeDatePickerCheckIn}
                value={dayjs(checkInTime)}
                format="YYYY-MM-DD HH:mm:ss"
                style={{ minWidth: "8.75rem" }}
                placement={"bottomLeft"}
                disabledDate={rooms.value.room_sale_id ? null : disabledBeforeDate}
                //disabledTime={disabledTime}
              />
            </div>
            <div className="flex-row">
              <h3 className="label">전화번호 &nbsp;:</h3>
              <Input
                placeholder="-없이"
                value={phones}
                onChange={handleInputChange_Phones}
                onKeyDown={handleInputNumberKeyDown}
              />
            </div>
            <div className="flex-row">
              <h3 className="label">퇴실예정 &nbsp;:</h3>
              <DatePicker
                showTime
                onChange={onChangeDatePickerCheckOut}
                value={dayjs(checkOutTime)}
                format="YYYY-MM-DD HH:mm:ss"
                style={{ minWidth: "8.75rem" }}
                placement={"bottomLeft"}
                disabledDate={rooms.value.room_sale_id ? null : disabledBeforeDate}
                //disabledTime={disabledTime}
              />
            </div>
            <div className="flex-row">
              <h3 className="label">차량번호 &nbsp;:</h3>
              <Input
                placeholder="차량번호 입력"
                value={carNo}
                onChange={handleInputChange}
              />
            </div>
          </div>
        ) : (
          // 모바일인 경우
          <div className="flex-row flex-wrap gap-16 sec-2 mb-16">
            <div className="flex-row">
              <h3 className="label">입실시간 &nbsp;:</h3>
              <DatePicker
                showTime
                onChange={onChangeDatePickerCheckIn}
                value={dayjs(checkInTime)}
                format="YYYY-MM-DD HH:mm:ss"
                style={{ minWidth: "8.75rem" }}
                placement={"bottomLeft"}
                disabledDate={rooms.value.room_sale_id ? null : disabledBeforeDate}
                //disabledTime={disabledTime}
              />
            </div>
            <div className="flex-row">
              <h3 className="label">퇴실예정 &nbsp;:</h3>
              <DatePicker
                showTime
                onChange={onChangeDatePickerCheckOut}
                value={dayjs(checkOutTime)}
                format="YYYY-MM-DD HH:mm:ss"
                style={{ minWidth: "8.75rem" }}
                placement={"bottomLeft"}
                disabledDate={rooms.value.room_sale_id ? null : disabledBeforeDate}
                //disabledTime={disabledTime}
              />
            </div>
            <div className="flex-row">
              <h3 className="label">전화번호 &nbsp;:</h3>
              <Input
                placeholder="-없이"
                value={phones}
                onChange={handleInputChange_Phones}
                onKeyDown={handleInputNumberKeyDown}
              />
            </div>
            <div className="flex-row">
              <h3 className="label">차량번호 &nbsp;:</h3>
              <Input
                placeholder="차량번호 입력"
                value={carNo}
                onChange={handleInputChange}
              />
            </div>
          </div>
        )}

        {/* 객실요금 */}
        <div className="flex-row flex-wrap gap-16 sec-2 mb-16">
          <div className="flex-row">
            <h3 className="label">객실요금 &nbsp;:</h3>
            <InputNumber
              //min={1}
              id="fee"
              max={999999999999}
              step={5000}
              style={{ maxWidth: "10.625rem", minWidth: "7.625rem" }}
              value={fee}
              formatter={formatNumber}
              onChange={onChangeInputNumber2}
              onKeyDown={handleInputNumberKeyDown}
              onInput={handleInputNumberOnInput}
            />
          </div>
          <div className="text-red">{change > 0 ? "미수금 : "+ change : ""}</div>
          <div className="btn-group">
            <Button
              type="primary"
              htmlType="button"
              onClick={() =>
                addInput(
                  "ACCOUNT",
                  Date.now(),
                  "",
                  0,
                  0,
                  0,
                  "",
                  "",
                  "",
                  null,
                  0
                )
              }
            >
              계좌추가
            </Button>
            <Button
              type="primary"
              htmlType="button"
              onClick={() =>
                addInput("AGENT", Date.now(), "", 0, 0, 0, "", "", "", null, 0)
              }
            >
              OTA추가
            </Button>
            <Button
              type="primary"
              htmlType="button"
              onClick={() =>
                addInput("CASH", Date.now(), "", 0, 0, 0, "", "", "", null, 0)
              }
            >
              현금추가
            </Button>
            <Button
              type="primary"
              htmlType="button"
              onClick={() =>
                addInput("CARD", Date.now(), "", 0, 0, 0, "", "", "", null, 0)
              }
            >
              카드추가
            </Button>
          </div>
        </div>

        {/* 결제일자 : 결제방식 추가버튼을 눌럿을때 생성됨 */}
        <div
          className="mb-16 payment-wrap"
          style={{ display: inputRows.length === 0 ? "none" : "block" }}
        >
          <div className="flex-col gap-8">
            {inputRows.map((input) => {
              //console.log("input ===> ", input);
              return (
                <div
                  className="flex-row flex-wrap gap-8 hr-b-8 plr-16"
                  key={input.id}
                >
                  {input.type === "ACCOUNT" && (
                    <>
                      <div className="flex-row">
                        <h3 className="label">결제일자 &nbsp;:</h3>
                        <DatePicker
                          onChange={(value) =>
                            handleChangePayDateValue(input.id, value)
                          }
                          style={{ minWidth: "8.75rem" }}
                          className="mr-16"
                          defaultValue={dayjs(input.payment_date)}
                          disabledDate={disabledDate}
                          onKeyDown={handleInputNumberKeyDown}
                          onInput={handleInputNumberOnInput}
                          placement={"topLeft"}
                        />
                      </div>
                      <div className="flex-row">
                        <h3 className="label">이체금액 &nbsp;:</h3>
                        <InputNumber
                          min={0}
                          max={999999999999}
                          step={5000}
                          className="mr-16"
                          style={{
                            maxWidth: "9.625rem",
                            minWidth: "7.625rem",
                          }}
                          defaultValue={input.accepted_cash}
                          formatter={formatNumber}
                          onChange={(value) =>
                            handleChangeAcceptedCash(input.id, value)
                          }
                          onKeyDown={handleInputNumberKeyDown}
                          onInput={handleInputNumberOnInput}
                        />
                      </div>
                      <div className="flex-row">
                        <h3 className="label">계좌번호 &nbsp;:</h3>
                        <Input
                          value={input.account_no}
                          onChange={(e) =>
                            handleChangeAccount(input.id, e.target.value)
                          }
                          placeholder="입력하세요"
                          className="flex-1"
                        />
                        <Button
                          type="primary"
                          htmlType="button"
                          onClick={() => removeInput(input.id)}
                          className="ml-8"
                          // disabled={inputRows.length <= 0 || !isEnabled}
                        >
                          삭제
                        </Button>
                      </div>
                    </>
                  )}
                  {input.type === "AGENT" && (
                    <>
                      <div className="flex-row">
                        <h3 className="label">결제일자 &nbsp;:</h3>
                        <DatePicker
                          onChange={(value) =>
                            handleChangePayDateValue(input.id, value)
                          }
                          // defaultValue={dayjs()}
                          style={{ minWidth: "8.75rem" }}
                          className="mr-16"
                          defaultValue={dayjs(input.payment_date)}
                          disabledDate={disabledDate}
                          onKeyDown={handleInputNumberKeyDown}
                          onInput={handleInputNumberOnInput}
                          placement={"topLeft"}
                        />
                      </div>
                      <div className="flex-row">
                        <h3 className="label">선결제액 &nbsp;:</h3>
                        <InputNumber
                          min={0}
                          max={999999999999}
                          step={5000}
                          className="mr-16"
                          style={{
                            maxWidth: "9.625rem",
                            minWidth: "7.625rem",
                          }}
                          defaultValue={input.amount_paid_agent}
                          formatter={formatNumber}
                          onChange={(value) =>
                            handleChangeAmountPaidAgent(input.id, value)
                          }
                          onKeyDown={handleInputNumberKeyDown}
                          onInput={handleInputNumberOnInput}
                        />
                      </div>
                      <div className="flex-row">
                        <h3 className="label">
                          대행사 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                        </h3>
                        <Select
                          showSearch
                          placeholder="선택하세요"
                          defaultValue={input.agent_type}
                          optionFilterProp="label"
                          onChange={(value) =>
                            handleChangeAgency(input.id, value)
                          }
                          onSearch={onSearchSelect1}
                          placement={"topLeft"}
                          value={input.agent_type}
                          options={codeAgentTypeList_selectbox}
                        />
                        <Button
                          type="primary"
                          htmlType="button"
                          onClick={() => removeInput(input.id)}
                          className="ml-8"
                          // disabled={inputRows.length <= 0 || !isEnabled}
                        >
                          삭제
                        </Button>
                      </div>
                    </>
                  )}
                  {input.type === "CASH" && (
                    <>
                      <div className="flex-row">
                        <h3 className="label">결제일자 &nbsp;:</h3>
                        <DatePicker
                          onChange={(value) =>
                            handleChangePayDateValue(input.id, value)
                          }
                          // defaultValue={dayjs()}
                          style={{ minWidth: "8.75rem" }}
                          className="mr-16"
                          defaultValue={dayjs(input.payment_date)}
                          disabledDate={disabledDate}
                          onKeyDown={handleInputNumberKeyDown}
                          onInput={handleInputNumberOnInput}
                          placement={"topLeft"}
                        />
                      </div>
                      <div className="flex-row">
                        <h3 className="label">받은현금 &nbsp;:</h3>
                        <InputNumber
                          min={0}
                          max={999999999999}
                          step={5000}
                          className="mr-16"
                          style={{
                            maxWidth: "9.625rem",
                            minWidth: "7.625rem",
                          }}
                          defaultValue={input.accepted_cash}
                          formatter={formatNumber}
                          onChange={(value) =>
                            handleChangeAcceptedCash(input.id, value)
                          }
                          onKeyDown={handleInputNumberKeyDown}
                          onInput={handleInputNumberOnInput}
                        />
                      </div>
                      <div className="flex-row">
                        <h3 className="label">거스름돈 &nbsp;:</h3>
                        <InputNumber
                          min={0}
                          max={999999999999}
                          step={5000}
                          style={{
                            maxWidth: "11.375rem",
                            minWidth: "7.625rem",
                          }}
                          defaultValue={input.changed_cash}
                          formatter={formatNumber}
                          onChange={(value) =>
                            handleChangeChangedCash(input.id, value)
                          }
                          onKeyDown={handleInputNumberKeyDown}
                          onInput={handleInputNumberOnInput}
                        />
                        <Button
                          type="primary"
                          htmlType="button"
                          onClick={() => removeInput(input.id)}
                          className="ml-8"
                          // disabled={inputRows.length <= 0 || !isEnabled}
                        >
                          삭제
                        </Button>
                      </div>
                    </>
                  )}
                  {input.type === "CARD" && (
                    <>
                      <div className="flex-row">
                        <h3 className="label">결제일자 &nbsp;:</h3>
                        <DatePicker
                          onChange={(value) =>
                            handleChangePayDateValue(input.id, value)
                          }
                          // defaultValue={dayjs()}
                          style={{ minWidth: "8.75rem" }}
                          className="mr-16"
                          defaultValue={dayjs(input.payment_date)}
                          disabledDate={disabledDate}
                          onKeyDown={handleInputNumberKeyDown}
                          onInput={handleInputNumberOnInput}
                          placement={"topLeft"}
                        />
                      </div>
                      <div className="flex-row">
                        <h3 className="label">결제금액 &nbsp;:</h3>
                        <InputNumber
                          min={0}
                          max={999999999999}
                          step={5000}
                          className="mr-16"
                          style={{
                            maxWidth: "9.625rem",
                            minWidth: "7.625rem",
                          }}
                          defaultValue={input.amount_paid_creadit_card}
                          formatter={formatNumber}
                          onChange={(value) =>
                            handleChangeAmountPaidCreaditCard(input.id, value)
                          }
                          onKeyDown={handleInputNumberKeyDown}
                          onInput={handleInputNumberOnInput}
                        />
                      </div>
                      <div className="flex-row">
                        <h3 className="label">
                          카드사 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                        </h3>
                        <Select
                          showSearch
                          placeholder="선택하세요"
                          className="mr-16"
                          defaultValue={input.credit_card_accepter_name}
                          optionFilterProp="label"
                          onChange={(value) =>
                            handleChangeCreditCardAccepterName(input.id, value)
                          }
                          onSearch={onSearchSelect1}
                          placement={"topLeft"}
                          options={[
                            {
                              value: "삼성카드",
                              label: "삼성카드",
                            },
                            {
                              value: "현대카드",
                              label: "현대카드",
                            },
                            {
                              value: "롯데카드",
                              label: "롯데카드",
                            },
                            {
                              value: "신한은행",
                              label: "신한은행",
                            },
                            {
                              value: "KB국민은행",
                              label: "KB국민은행",
                            },
                          ]}
                        />
                      </div>
                      <div className="flex-row">
                        <h3 className="label">승인번호 &nbsp;:</h3>
                        <Input
                          value={input.credit_card_approval_no}
                          onChange={(e) =>
                            handleChangeCreditCardApprovalNo(input.id, e.target.value)
                          }
                          placeholder="입력하세요"
                          className="flex-1"
                        />
                        <Button
                          type="primary"
                          htmlType="button"
                          onClick={() => removeInput(input.id)}
                          className="ml-8"
                          // disabled={inputRows.length <= 0 || !isEnabled}
                        >
                          삭제
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        {/* 고객메모 */}
        <div className="flex-row mb-16">
          <h3 className="label">고객메모 &nbsp;:</h3>
          <Input
            // value={roomRes.value.momo}
            //placeholder="고객메모는 매출별 적용됩니다.(객실 표시는 상당 [상태정보] 탭의 [객실표시]란에 메모해 주세요.)"
            onChange={handleInputChange_Note}
            value={note}
          />
        </div>

        {/* 알림 */}
        <div className="flex-row flex-wrap gap-16 sec-2 mb-16">
          <div className="flex-row">
            <Checkbox onChange={onChangeCheckBox1}>알림</Checkbox>
            <span>20시 58분에 고객 메모 팝업</span>
          </div>
          <div className="flex-row">
            <Switch onChange={onChangeSwitch1} /> &nbsp;&nbsp;
            <span>알림시 고객 메모 음성 재생</span>
          </div>
        </div>
      </div>

      <Modal
        destroyOnClose={true}
        title="객실 선택"
        open={isModalOpenRoomSel}
        //onOk={handleOkModalRoomSel}
        //okText="저장"
        onCancel={handleCancelModalRoomSel}
        //cancelText="닫기"
        footer={null}
        width={500}
        maskClosable={false}
      >
        <ModalRoomSelecter roomSaleId={rooms.value.room_sale_id} closeModal={handleCancelModalRoomSel} refreshRoom={refreshRoom} />
      </Modal>
    </form>
  );
});
