import React, { useState, useEffect } from "react";
import { Button, Input, InputNumber, message } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import {
  handleInputNumberKeyDown,
  handleInputNumberOnInput,
  formatNumber,
} from "../util";
import { accomIdApi, accomUpdateApi } from "../api/api";

const { TextArea } = Input;

export const SettingSMS = () => {
  // 업소 정보 (API 연결)
  const [acoomList, setAcoomList] = useState([]);
  const [reserveSmsConfigUse, setReserveSmsConfigUse] = useState(false);
  const [reserveSmsConfigTimeToSend, setReserveSmsConfigTimeToSend] = useState(0);
  const [reserveSmsConfigMessage, setReserveSmsConfigMessage] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await accomIdApi();
        const resArray = Object.entries(res.accoms);
        const acoomAllArray = resArray.map(([key, value]) => {
          return {
            key: key,
            value: value,
            //예약시 투숙객 문자 알림 서비스 설정.
            reserveSmsConfigUse: value.reserve_sms_config.use,
            reserveSmsConfigTimeToSend: value.reserve_sms_config.time_to_send,
            reserveSmsConfigMessage: value.reserve_sms_config.footer_message,
          };
        });
        setAcoomList(acoomAllArray);
        setReserveSmsConfigUse(acoomAllArray[0].reserveSmsConfigUse);
        setReserveSmsConfigTimeToSend(acoomAllArray[0].reserveSmsConfigTimeToSend);
        setReserveSmsConfigMessage(acoomAllArray[0].reserveSmsConfigMessage);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  
  // InputNumber
  const onChangeInputNumber = (value) => {
    console.log("changed", value);
  };

  // TextArea
  const handleChangeTextarea = (e) => {
    setReserveSmsConfigMessage(e.target.value);
  };

  const handleSave = async () => {
    let param = {
      "reserve_sms_config": {
        "use": true, 
        "time_to_send": reserveSmsConfigTimeToSend,
        "footer_message": reserveSmsConfigMessage,
      },
    };
    console.log(param);
    
    try {
      const res = await accomUpdateApi(param);
      //console.log(res);
      if (Object.entries(res.accoms).length > 0) {
        message.info("저장되었습니다.");
      } else {
        message.error("저장중 문제가 발생했습니다. 다시 저장해주세요.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleCancel = async () => {
    let param = {
      "reserve_sms_config": {
        "use": false, 
      },
    };
    console.log(param);
    
    try {
      const res = await accomUpdateApi(param);
      //console.log(res);
      if (Object.entries(res.accoms).length > 0) {
        message.info("저장되었습니다.");
      } else {
        message.error("저장중 문제가 발생했습니다. 다시 저장해주세요.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="pt-16 plr-24 pb-24" style={{ height: "42.438rem" }}>
      <h2 className="mb-16">예약 문자 설정</h2>

      <div className="mobile-bg"></div>
      <div className="cont-area">
        <div className="flex-row gap-8 flex-wrap pt-16">
          <h4>미리예약 일괄 발송 시간</h4>
          <InputNumber
            min={1}
            max={9999}
            defaultValue={reserveSmsConfigTimeToSend}
            formatter={formatNumber}
            onChange={onChangeInputNumber}
            onKeyDown={handleInputNumberKeyDown}
            onInput={handleInputNumberOnInput}
          />
          <h4>시 발송</h4>
        </div>

        <p className="font-13 text-gray-500 flex-row flex-items-start mtb-8 text-l">
          <QuestionCircleFilled
            className="mr-8"
            style={{ width: "14px", height: "14px", marginTop: "2px" }}
          />
          당일 예약은 즉시 문자가 발송되며, 미리 예약은 입실 당일, 위에 설정한
          시간대에 일관 발송 됩니다.
        </p>

        <div className="flex-row gap-8 flex-wrap pt-16">
          <h4>문자 하단 사용자 지정 문구</h4>
          <TextArea
            value={reserveSmsConfigMessage}
            onChange={handleChangeTextarea}
            rows={9}
            maxLength={60}
            //allowClear
            count={{
              show: true,
              max: 60,
            }}
            style={{ resize: "none", minHeight: "18.75rem" }}
            placeholder="여기에 내용을 입력하세요 (60자 이하)"
          />
        </div>

        <div className="flex-row gap-8 flex-wrap pt-24 pb-16 mt-auto">
          <Button type="defalut" htmlType="button" className="flex-1" onClick={handleCancel}>
            삭제
          </Button>
          <Button type="primary" htmlType="button" className="flex-1" onClick={handleSave}>
            저장
          </Button>
        </div>
      </div>
    </div>
  );
};
