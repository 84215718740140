import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  ContainerOutlined,
  HomeOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  AuditOutlined,
  FormatPainterOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Menu } from "antd";

export const MainMenu = (props) => {
  // 모바일인지 여부를 판단하는 상태 추가
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // 초기화 및 리사이즈 이벤트 핸들러 설정
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 870); // 768px 이하이면 모바일로 판단
    };

    handleResize(); // 초기 상태 설정
    window.addEventListener("resize", handleResize); // 윈도우 크기 변경 이벤트 감지

    return () => {
      window.removeEventListener("resize", handleResize); // 컴포넌트 언마운트 시 이벤트 제거
    };
  }, []);

  console.log("모바일화면" + isMobile);
  // 메뉴 항목 정의
  const items = [
    {
      key: "1",
      icon: <HomeOutlined />,
      label: <Link to={"/"}>체크인/아웃</Link>,
    },
    {
      key: "4",
      icon: <AuditOutlined />,
      label: <Link to={"/reserve"}>예약</Link>,
      children: [
        {
          key: "menu1",
          label: <Link to={"/reserve"}>예약관리</Link>,
        },
        {
          key: "menu2",
          label: (
            <Link to={"https://account.yanolja.biz/"} target="_blank">
              야놀자
            </Link>
          ),
        },
        {
          key: "menu3",
          label: (
            <Link to={"https://ad.goodchoice.kr/login"} target="_blank">
              여기어때
            </Link>
          ),
        },
        {
          key: "menu4",
          label: (
            <Link to={"https://partner.coolstay.co.kr/login"} target="_blank">
              꿀스테이
            </Link>
          ),
        },
        {
          key: "menu5",
          label: (
            <Link to={"https://www.shimple.co.kr/admin.do"} target="_blank">
              쉼플
            </Link>
          ),
        },
      ],
    },
    {
      key: "sub1",
      icon: <ContainerOutlined />,
      label: <Link to={"/list/salesinquiry"}>조회</Link>,
      children: [
        {
          key: "SalesInquiry",
          label: <Link to={"/list/salesinquiry"}>매출 조회</Link>,
        },
        {
          key: "CheckAdmissionHistory",
          label: <Link to={"/list/admissionhistory"}>입실 이력 조회</Link>,
        },
        // {
        //   key: "CheckRoomHistory",
        //   label: <Link to={"/list/roomhistory"}>객실 이력 조회</Link>,
        // },
        {
          key: "MileageMemberInquiry",
          label: <Link to={"/list/mileage"}>마일리지 회원 조회</Link>,
        },
      ],
    },
    !isMobile && {
      key: "sub2",
      icon: <FormatPainterOutlined />,
      label: <Link to={"/manage/roominfo"}>관리</Link>,
      children: [
        {
          key: "RoomInfoManage",
          label: <Link to={"/manage/roominfo"}>객실 정보 관리</Link>,
        },
        {
          key: "RoomTypeManage",
          label: <Link to={"/manage/roomtype"}>객실 유형 관리</Link>,
        },
        {
          key: "DoorlockInfoManage",
          label: <Link to={"/manage/doorlockinfo"}>도어락 정보 관리</Link>,
        },
        {
          key: "QRTextManage",
          label: <Link to={"/manage/qrtext"}>QR 문자 관리</Link>,
        },
      ],
    },
    // 모바일 화면에서는 sub3를 렌더링하지 않음
    !isMobile && {
      key: "sub3",
      icon: <SettingOutlined />,
      label: <Link to={"/setting/ratetime"}>설정</Link>,
      children: [
        {
          key: "SettingRateTime",
          label: <Link to={"/setting/ratetime"}>요금 및 시간 설정</Link>,
        },
        {
          key: "SettingOperateRule",
          label: <Link to={"/setting/operaterule"}>운영 규칙 설정</Link>,
        },
        // {
        //   key: "SettingScreen",
        //   label: <Link to={"/setting/screen"}>화면 설정</Link>,
        // },
        {
          key: "SettingReservation",
          label: <Link to={"/setting/reservation"}>예약 연동 설정</Link>,
        },
        {
           key: "SettingSMS",
           label: <Link to={"/setting/sms"}>예약 문자 설정</Link>,
        },
      ],
    },
    // {
    //   key: "5",
    //   icon: <AreaChartOutlined />,
    //   label: <Link to={"/dashbord"}>대시보드</Link>,
    // },
    {
      key: "6",
      icon: <MenuUnfoldOutlined />,
      label: <Link to={"https://chatgpt.com/?model=gpt-4o"} target="_blank">ChatGPT</Link>,
    },
  ].filter(Boolean); // undefined 항목을 제거하기 위해 filter(Boolean) 사용

  const onClick = (e) => {
    props.setCurrentMenuKey(e.key);
  };

  return (
    <>
      <div className={!props.collapsed ? "menu-wrap" : "menu-wrap fold"}>
        <Menu
          defaultSelectedKeys={["1"]}
          mode="inline"
          inlineCollapsed={props.collapsed}
          items={items}
          onClick={onClick}
          selectedKeys={[props.currentMenuKey]}
        />
        <div className="flex-row">
          <Button
            type="primary"
            onClick={props.toggleCollapsed}
            className="btn-menu-fold"
            style={{
              marginTop: 16,
              width: "80px",
              padding: "4px 32px",
            }}
          >
            {props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
        </div>
      </div>
    </>
  );
};

export default MainMenu;
