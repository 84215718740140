import React, { useCallback, useEffect, useState, } from "react";
import {
  Button, Checkbox, ConfigProvider,
  Popconfirm, Select, Table,
  message, Input, InputNumber,
  Divider, Modal
} from "antd";
import { AlertOutlined } from "@ant-design/icons";
import koKR from "antd/lib/locale/ko_KR";
import {
  roomAllByAccomSearchApi,
  roomInsertApi,
  roomUpdateApi,
  roomDeleteApi,
} from "../api/api";
import {debounce} from "lodash";

export const RoomInfoManage = ({ roomTypeAllList }) => {
  /*
  미작업내역
  - gid, lid 중복체크 추가 0~255까지 가능하며 객실끼리 중복되지 않아야함
  */
  const [roomAllList, setRoomAllList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [editRows, setEditRows] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  // 룸정보 (API 연결)
  useEffect(() => {
    setRefresh(false);
    const fetchData = async () => {
      try {
        const res = await roomAllByAccomSearchApi();
        const resArray = Object.entries(res.rooms);
        const roomAllArray = resArray
          .map(([key, value]) => {
            return {
              key: key,
              //value: value,
              no: value.no,
              room_type_id: value.room_type_id,
              display_name : value.display_name,
              gid : value.gid,
              lid : value.lid,
              floor : value.floor,
              card_barcode : value.card_barcode,
              use_keyless : value.use_keyless,
            };
          })
          .sort((a, b) => a.no - b.no);
        setRoomAllList(roomAllArray);
        //console.log(resArray);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    
  }, [refresh]);

  const addRow = (
    key,
    room_type_id,
    display_name,
    gid,
    lid,
    floor,
    card_barcode,
    use_keyless
  ) => {
    setRoomAllList((inputRows) => [
      ...inputRows,
      {
        key: key,
        room_type_id: room_type_id,
        display_name: display_name,
        gid: gid,
        lid: lid,
        floor: floor,
        card_barcode: card_barcode,
        use_keyless: use_keyless
      },
    ]);
  };

  // 객실이름 필터
  const filteredName = [
    ...new Set(roomAllList?.map((item) => item.display_name)),
  ].map((name) => ({
    text: name,
    value: name,
  }));

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
    
  const debouncedInputChange = useCallback(
    debounce((key, column, value) => {
      setRoomAllList((prevList) =>
        prevList.map((item) => {
          //item.key === key ? { ...item, [column]: value } : item
          if (item.key === key) {
            const updatedItem = { ...item, [column]: value };

            setEditRows((prevList) =>{
              const isAlreadyModified = prevList.find((data) => data.key === key);
              if(isAlreadyModified){
                return prevList.map((data) => data.key === key ? updatedItem : data );
              }else{
                return [...prevList, updatedItem];
              }
            });
            return updatedItem;
          }
          return item;
        })
      );
    }, 300),
    []
  );

  const handleNew = async () => {
    let param = {
      "room_type_id": "",
      "display_name": "",
      "gid": 0,
      "lid": 0,
      "floor": 0,
      "card_barcode": 0,
      "use_keyless": false,
    };
    
    try {
      const res = await roomInsertApi(param);
      console.log(res);
      if (Object.entries(res.rooms).length > 0) {
        message.info("추가되었습니다.");
        addRow(Object.entries(res.rooms)[0][0], param.room_type_id, param.display_name, param.gid, param.lid, param.floor, param.card_barcode, param.use_keyless);
        console.log(roomAllList);
      } else {
        message.error("작업중 문제가 발생했습니다. 다시 시도해주세요.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = () => {
    console.log(editRows);
    editRows.map( async (data) => {
      let param = {
        "display_name": data.display_name,
        "gid": data.gid,
        "lid": data.lid,
        "room_type_id": data.room_type_id,
        "floor": data.floor,
        "card_barcode": data.card_barcode,
        "use_keyless": data.use_keyless,
      };
      console.log(param);

      try {
        const res = await roomUpdateApi(data.key, param);
        console.log(res);
        if (data.key === Object.entries(res.rooms)[0][0]) {
          message.info("저장되었습니다.");
          //setRefresh(true);
        } else {
          message.error("저장중 문제가 발생했습니다. 다시 저장해주세요.");
        }
      } catch (error) {
        console.log(error);
      }
    });
  };
  
  const handleDelete = async (data) => {
    Modal.confirm({
      title: `객실 유형 [${data.display_name}] 삭제하시겠습니까?`,
      width: "440px",
      icon: (<AlertOutlined style={{ transform: "scale(1.3)", color: "#a46bff" }} />),
      okText: "예",
      cancelText: "아니오",
      centered: true,
      onOk: async () => {
        const res = await roomDeleteApi(data.key);
        if(data.key === Object.entries(res.rooms)[0][0]){
          message.info("삭제되었습니다.");
          setRoomAllList((prevList) => prevList.filter((item) => item.key !== data.key));
          setEditRows((prevList) => prevList.filter((item) => item.key !== data.key));
        } else {
          message.error("작업중 문제가 발생했습니다. 다시 시도해주세요.");
        }
      },
      onCancel() { },
    });
  };

  const handleCancel = () => {
    //setRefresh(true);
    window.location.reload();
  }

  // 테이블 컬럼 생성
  const columns = [
    {
      title: "No.",
      render: (text, record, index) => index + 1,
      width: "5%",
      align: "center",
    },
    {
      title: "객실이름",
      dataIndex: "display_name",
      key: "display_name",
      width: "15%",
      render: (_, record) => {
        return (
          <Input
            defaultValue={record.display_name}
            onChange={(e) =>
              debouncedInputChange(record.key, "display_name", e.target.value)
            }
          />
        );
      },
      filterSearch: true,
      filters: filteredName,
      filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.display_name.includes(value),
      sorter: (a, b) =>
        a.display_name.localeCompare(b.display_name),
      sortOrder: sortedInfo.columnKey === "display_name" ? sortedInfo.order : null,
      ellipsis: true,
      align: "center",
    },
    {
      title: "GID",
      dataIndex: "gid",
      key: "gid",
      width: "10%",
      align: "center",
      render: (_, record) => {
        return (
          <InputNumber
            min={0}
            max={255}
            defaultValue={record.gid}
            onChange={(value) =>
              debouncedInputChange(record.key, "gid", value)
            }
          />
        );
      },
    },
    {
      title: "LID",
      dataIndex: "lid",
      key: "lid",
      width: "10%",
      align: "center",
      render: (_, record) => {
        return (
          <InputNumber
            min={0}
            max={255}
            defaultValue={record.lid}
            onChange={(value) =>
              debouncedInputChange(record.key, "lid", value)
            }
          />
        );
      },
    },
    {
      title: "객실유형",
      dataIndex: "room_type_id",
      key: "room_type_id",
      align: "center",
      render: (_, record) => (
        <Select
          placeholder="선택하세요"
          defaultValue={roomTypeAllList?.find( (roomType) => roomType.key === record.room_type_id)?.value.display_name || "" }
          style={{ width: "100%", textAlign: "left" }}
          onChange={(key, value) => debouncedInputChange(record.key, "room_type_id", value.key) }
        >
          {roomTypeAllList.map((roomType, idx) => {
              return (
                <Select.Option
                  key={roomType.key}
                  value={roomType.value.display_name}
                >
                  {roomType.value.display_name}
                </Select.Option>
              );
            })
            .sort((a, b) => a.props.value.localeCompare(b.props.value))}
        </Select>
      ),
      width: "20%",
    },
    {
      title: "층수",
      dataIndex: "floor",
      key: "floor",
      width: "10%",
      align: "center",
      render: (_, record) => {
        return (
          <InputNumber
            min={-127}
            max={127}
            defaultValue={record.floor}
            onChange={(value) =>
              debouncedInputChange(record.key, "floor", value)
            }
          />
        );
      },
    },
    {
      title: "바코드",
      dataIndex: "card_barcode",
      key: "card_barcode",
      width: "10%",
      align: "center",
      render: (_, record) => {
        return (
          <InputNumber
            min={0}
            max={127}
            defaultValue={record.card_barcode}
            onChange={(value) =>
              debouncedInputChange(record.key, "card_barcode", value)
            }
          />
        );
      },
    },
    {
      title: "키리스",
      dataIndex: "use_keyless",
      key: "use_keyless",
      //render: (_, record) => <Checkbox defaultChecked={record.use_keyless || ""} onChange={onChangeCheckbox}/>,
      render: (_, record) => <Checkbox defaultChecked={record.use_keyless || ""} onChange={(e) => debouncedInputChange(record.key, "use_keyless", e.target.checked)}/>,
      width: "10%",
      align: "center",
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      width: "10%",
      render: (_, record) => <Button type="primary" danger size="small" onClick={() => handleDelete(record)}>삭제</Button>,
      align: "center",
    },
  ];
  
  
  return (
    <>
      <div className="p-16 pb-0">
        <h2 className="mb-16">객실 정보 관리</h2>

        <ConfigProvider locale={koKR}>
          {/*
          <div className="flex-row flex-wrap gap-8 mt-16 mb-8">
            <div className="flex-row flex-wrap gap-8 ml-auto">
              <Button type="primary" htmlType="button" onClick={() => addRow(0,0)}>
                객실추가
              </Button>
            </div>
          </div>
          */}
          <div className="table-wrap-antd">
            <Table
              columns={columns}
              dataSource={roomAllList}
              onChange={handleChange}
              pagination={false}//{pagination}
              rowKey="key"
              scroll={{ y: "28.5rem" }}
              className="ant-table-respons"
            />
          </div>
        </ConfigProvider>
      </div>
      <Divider />
      <div className="p-16 pt-0">
        <div className="flex-row flex-wrap gap-8 mt-16 mb-8">
          <div className="btn-group m-auto">
            <Button type="default" htmlType="button" onClick={handleNew}>
              추가
            </Button>
            <Button type="primary" htmlType="button" onClick={handleSave}>
              저장
            </Button>
            <Button type="default" htmlType="button" onClick={handleCancel}>
              취소
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
