import React, { useCallback, useEffect, useState } from "react";
import koKR from "antd/lib/locale/ko_KR";
import {
  Button, ConfigProvider, Form,
  Input, InputNumber, Popconfirm,
  Table, Divider, Modal, message,
} from "antd";
import { AlertOutlined } from "@ant-design/icons";
import { handleInputNumberKeyDown, handleInputNumberOnInput } from "../util";
import { 
  roomTypeAllByAccomSearchApi, 
  roomTypeInsertApi,
  roomTypeUpdateApi,
  roomTypeDeleteApi 
} from "../api/api";
import { debounce } from "lodash";

export const RoomTypeManage = () => {
  //const [dataSource, setDataSource] = useState(roomTypeAllList);
  
  const [refresh, setRefresh] = useState(false);
  const [editRows, setEditRows] = useState([]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });

  // 룸타입 정보 (API 연결)
  const [roomTypeAllList, setRoomTypeAllList] = useState([]);
  useEffect(() => {
    setRefresh(false);
    const fetchData = async () => {
      try {
        const res = await roomTypeAllByAccomSearchApi();
        const resArray = Object.entries(res.room_types);
        const roomTypeAllArray = resArray.map(([key, value]) => {
          return {
            key: key,
            //value: value,
            display_name: value.display_name,
            basic_capacity: value.basic_capacity,
            maximum_capacity: value.maximum_capacity,
          };
        });
        setRoomTypeAllList(roomTypeAllArray);
        console.log("roomTypeAllList ==> ", roomTypeAllArray);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [refresh]);

  const addRow = (
    key,
    display_name,
    basic_capacity,
    maximum_capacity
  ) => {
    setRoomTypeAllList((inputRows) => [
      ...inputRows,
      {
        key: key,
        display_name: display_name,
        basic_capacity: basic_capacity,
        maximum_capacity: maximum_capacity
      },
    ]);
  };

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setPagination(pagination);
  };

  const handleDelete = (data) => {
    //const newData = dataSource.filter((item) => item.key !== key);
    //setDataSource(newData);
    //console.log("dataSource ==> ", newData);
    Modal.confirm({
      title: `객실 유형 [${data.display_name}] 삭제하시겠습니까?`,
      width: "440px",
      icon: (<AlertOutlined style={{ transform: "scale(1.3)", color: "#a46bff" }} />),
      okText: "예",
      cancelText: "아니오",
      centered: true,
      onOk: async () => {
        const res = await roomTypeDeleteApi(data.key);
        if(data.key === Object.entries(res.room_types)[0][0]){
          message.info("삭제되었습니다.");
          setRoomTypeAllList((prevList) => prevList.filter((item) => item.key !== data.key));
          setEditRows((prevList) => prevList.filter((item) => item.key !== data.key));
        } else {
          message.error("작업중 문제가 발생했습니다. 다시 시도해주세요.");
        }
      },
      onCancel() { },
    });
  };
  const handleInputChange = (key, column, value) => {
    /*
    const newData = dataSource.map((item) => {
      if (item.key === key) {
        return { ...item, [column]: value }; // 해당 컬럼 업데이트
      }
      return item;
    });
    setDataSource(newData);
    */
  };

  const debouncedInputChange = useCallback(
    debounce((key, column, value) => {
      setRoomTypeAllList((prevList) =>
        prevList.map((item) => {
          //item.key === key ? { ...item, [column]: value } : item
          if (item.key === key) {
            const updatedItem = { ...item, [column]: value };

            setEditRows((prevList) =>{
              const isAlreadyModified = prevList.find((data) => data.key === key);
              if(isAlreadyModified){
                return prevList.map((data) => data.key === key ? updatedItem : data );
              }else{
                return [...prevList, updatedItem];
              }
            });
            return updatedItem;
          }
          return item;
        })
      );
    }, 300),
    []
  );

  const columns = [
    {
      title: "No.",
      render: (text, record, index) => index + 1,
      width: "10%",
      align: "center",
    },
    {
      title: "객실 유형명",
      dataIndex: "display_name",
      key: "display_name",
      render: (_, record) => {
        return (
          <Input
            defaultValue={record.display_name}
            onChange={(e) => debouncedInputChange(record.key, "display_name", e.target.value)}
          />
        );
      },
      align: "center",
    },
    {
      title: "기본 인원",
      dataIndex: "basic_capacity",
      key: "basic_capacity",
      render: (_, record) => {
        return (
          <InputNumber
            min={0}
            defaultValue={record.basic_capacity || 0}
            onChange={(value) => debouncedInputChange(record.key, "basic_capacity", value)}
            onKeyDown={handleInputNumberKeyDown}
            onInput={handleInputNumberOnInput}
          />
        );
      },
      width: "15%",
      align: "center",
    },
    {
      title: "최대 인원",
      dataIndex: "maximum_capacity",
      key: "maximum_capacity",
      render: (_, record) => {
        return (
          <InputNumber
            min={0} // 최소값 설정
            defaultValue={record.maximum_capacity || 0}
            onChange={(value) => debouncedInputChange(record.key, "maximum_capacity", value)}
            onKeyDown={handleInputNumberKeyDown}
            onInput={handleInputNumberOnInput}
          />
        );
      },
      width: "15%",
      align: "center",
    },
    {
      title: "삭제",
      key: "삭제",
      render: (_, record) => <Button type="primary" danger size="small" onClick={() => handleDelete(record)}>삭제</Button>,
      width: "10%",
      align: "center",
    },
  ];

  const handleNew = async () => {
    let param = {
      "display_name": "",
      "basic_capacity": 0,
      "maximum_capacity": 0
    };
    
    try {
      const res = await roomTypeInsertApi(param);
      console.log(res);
      if (Object.entries(res.room_types).length > 0) {
        message.info("추가되었습니다.");
        addRow(Object.entries(res.room_types)[0][0], res.display_name, res.basic_capacity, res.maximum_capacity);
        console.log(roomTypeAllList);
      } else {
        message.error("작업중 문제가 발생했습니다. 다시 시도해주세요.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = () => {
    console.log(editRows);
    editRows.map( async (data) => {
      let param = {
        "display_name": data.display_name,
        "basic_capacity": data.basic_capacity,
        "maximum_capacity": data.maximum_capacity
      };
      console.log(param);
      
      try {
        const res = await roomTypeUpdateApi(data.key, param);
        console.log(res);
        if (data.key === Object.entries(res.room_types)[0][0]) {
          message.info("저장되었습니다.");
          //setRefresh(true);
        } else {
          message.error("저장중 문제가 발생했습니다. 다시 저장해주세요.");
        }
      } catch (error) {
        console.log(error);
      }
    });
  };
  
  const handleCancel = () => {
    //setRefresh(true);
    window.location.reload();
  }


  return (
    <>
      <div className="p-16 pb-0">
        <h2 className="mb-16">객실 유형 관리</h2>

        <ConfigProvider locale={koKR}>
          <div className="table-wrap-antd">
            <Table
              columns={columns}
              dataSource={roomTypeAllList}
              onChange={handleChange}
              pagination={false}//{pagination}
              rowKey="key"
              scroll={{ y: "32.5rem" }}
              className="ant-table-respons"
            />
          </div>
        </ConfigProvider>
      </div>
      <Divider />
      <div className="p-16 pt-0">
        <div className="flex-row flex-wrap gap-8 mt-16 mb-8">
          <div className="btn-group m-auto">
            <Button type="default" htmlType="button" onClick={handleNew}>
              추가
            </Button>
            <Button type="primary" htmlType="button" onClick={handleSave}>
              저장
            </Button>
            <Button type="default" htmlType="button" onClick={handleCancel}>
              취소
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
