import React, { useEffect, useState } from "react";
import * as XLSX from 'xlsx';
import dayjs from "dayjs";
import koKR from "antd/lib/locale/ko_KR";
import { roomStateLogApi } from "../api/api";
import { Button, ConfigProvider, DatePicker, Table } from "antd";
import { disabledDate, disabledTime } from "../util";
import { useCode } from "../login/CodeContext";

export const CheckRoomHistory = () => {
  // API 테스트
  const [roomLogList, setRoomLogList] = useState([]);
  const [columns, setColumns] = useState([]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({ current: 1, pageSize: 15 });

  //const [startDateTime, setStartDateTime] = useState(moment().format('YYYY-MM-DD HH:mm:ss'));
  const [startDateTime, setStartDateTime] = useState(dayjs(dayjs().format("YYYY-MM-DD") + " 00:00:00"));
  const [endDateTime, setEndDateTime] = useState(dayjs(dayjs().format("YYYY-MM-DD") + " 23:59:59"));

  const { coderead } = useCode();
  const code = coderead();
  const codeRoom = Object.entries(code.room);
  const codeRoomList = codeRoom.map(([key, value]) => {
    return {
      key: key,
      value: value.display_name,
    };
  });
  const codeRoomType = Object.entries(code.roomType);
  const codeRoomTypeList = codeRoomType.map(([key, value]) => {
    return {
      key: key,
      value: value.display_name,
    };
  });
  const codeStayType = Object.entries(code.stayType);
  const codeStayTypeList = codeStayType.map(([key, value]) => {
    return { key: key, value: value };
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await roomStateLogApi(startDateTime.valueOf(), endDateTime.valueOf());
        
        const resArray = Object.entries(res.room_state_logs);
        // const stateLogArray = resArray.map(([key, value]) => {
        //   value.key = key;
        //   value.room_state_log_id = key;
        //   value.registed_time_date = dayjs(value.registed_time).format("YYYY-MM-DD");
        //   value.registed_time_time = dayjs(value.registed_time).format("HH:mm:ss");
        //   value.change_data_name = "";

        //   if (
        //     typeof value.changed_datas.key != "undefined" &&
        //     value.changed_datas.key != ""
        //   ) {
        //     value.key_name = value.changed_datas.key;
        //   }

        //   if (
        //     typeof value.changed_datas.previous_key != "undefined" &&
        //     value.changed_datas.previous_key != ""
        //   ) {
        //     value.previous_key_name = value.changed_datas.previous_key;
        //   }

        //   // value.key = key;
        //   // value.value = value;
        //   // value.saleId = value.sale_id;
        //   // value.roomId = value.room_id;
        //   // value.roomReserveId = value.room_reserve_id;
        //   // value.roomTypeId = value.room_type_id;
        //   // value.stateLogDate = value.registed_time;
        //   // value.stateLogDateFormat = dayjs(value.registed_time).format("YYYY-MM-DD");
        //   // value.stateLogTimeFormat = dayjs(value.registed_time).format("HH:mm:ss");
        //   return value;
        // });

        const stateLogArray = resArray.map(([key, value]) => {
          value.key = key;
          value.value = value;
          value.registed_time_date = dayjs(value.registed_time).format(
            "YYYY-MM-DD"
          );
          value.registed_time_time = dayjs(value.registed_time).format(
            "HH:mm:ss"
          );

          if (Object.keys(value.changed_datas).includes("is_interrupt")) {
            value.changed_datas_name = "객실 클릭";
          } else if (
            Object.keys(value.changed_datas).includes("clean_order")
          ) {
            let add_msg = "";
            if (Object.keys(value.changed_datas).includes("stay_type")) {
              add_msg = value.changed_datas.stay_type === null ? "(퇴실 -> 공실)" : "";
            }
            value.changed_datas_name =  ((value.changed_datas.clean_order ? "청소요청" : "청소완료") + add_msg);
          } else if (Object.keys(value.changed_datas).includes("outing")) {
            value.changed_datas_name = "외출";
          } else if (
            Object.keys(value.changed_datas).includes("stay_type")
          ) {
            value.changed_datas_name =  (
              codeStayTypeList?.find(
                (code) => code.key === value.changed_datas.stay_type
              )?.value || ""
            );
          } else if (
            Object.keys(value.changed_datas).includes("power_down_request")
          ) {
            value.changed_datas_name = value.changed_datas.power_down_request
              ? "전원강제차단"
              : "";
          } else if (
            Object.keys(value.changed_datas).includes("inspect_order")
          ) {
            value.changed_datas_name = value.changed_datas.inspect_order ? "점검요청" : "점검완료";
          } else {
            value.changed_datas_name = "변경내용 미설정";
          }

          return value;
        });

        setRoomLogList(stateLogArray);

        let registed_time_date_filter = [
          ...new Set(stateLogArray.map((item) => item.registed_time_date)),
        ].map((registed_time_date) => ({
          value: registed_time_date,
          text: registed_time_date,
        }));

        let registed_time_time_filter = [
          ...new Set(stateLogArray.map((item) => item.registed_time_time)),
        ].map((registed_time_time) => ({
          value: registed_time_time,
          text: registed_time_time,
        }));

        let room_id_filter = [
          ...new Set(stateLogArray.map((item) => item.room_id)),
        ].map((room_id) => ({
          value: room_id,
          text: room_id,
        }));

        let check_in_time_filter = [
          ...new Set(stateLogArray.map((item) => item.check_in_time)),
        ].map((check_in_time) => ({
          value: check_in_time,
          text: check_in_time,
        }));

        let check_out_time_filter = [
          ...new Set(stateLogArray.map((item) => item.check_out_time2)),
        ].map((check_out_time2) => ({
          value: check_out_time2,
          text: check_out_time2,
        }));

        let changed_datas_name_filter = [
          ...new Set(stateLogArray.map((item) => item.changed_datas_name)),
        ].map((changed_datas_name) => ({
          value: changed_datas_name,
          text: changed_datas_name,
        }));

        // let channel_id_filter = [
        //   ...new Set(stateLogArray.map((item) => item.channel_id)),
        // ].map((channel_id) => ({
        //   value: channel_id,
        //   text: channel_id,
        // }));

        // console.log("channel_id_filter");
        // console.log(channel_id_filter);

        // let phones_filter = [
        //   ...new Set(stateLogArray.map((item) => item.phones)),
        // ].map((phones) => ({
        //   value: phones,
        //   text: phones,
        // }));

        // console.log("phones_filter");
        // console.log(phones_filter);

        // let car_no_filter = [
        //   ...new Set(stateLogArray.map((item) => item.car_no)),
        // ].map((car_no) => ({
        //   value: car_no,
        //   text: car_no,
        // }));

        // console.log("car_no_filter");
        // console.log(car_no_filter);

        // let person_count_filter = [
        //   ...new Set(
        //     stateLogArray.map((item) => item.person_count)
        //   ),
        // ].map((person_count) => ({
        //   value: person_count,
        //   text: person_count,
        // }));

        // console.log("person_count_filter");
        // console.log(person_count_filter);

        // let fee_filter = [
        //   ...new Set(
        //     stateLogArray.map((item) => item.fee)
        //   ),
        // ].map((fee) => ({
        //   value: fee,
        //   text: fee,
        // }));

        // console.log("fee_filter");
        // console.log(fee_filter);

        setColumns([
          {
            title: "날짜",
            dataIndex: "registed_time_date",
            key: "registed_time_date",
            filterSearch: true,
            filters: registed_time_date_filter,
            filteredValue: filteredInfo.registed_time_date || null,
            onFilter: (value, record) => record.registed_time_date === value,
            sorter: (a, b) =>
              a.registed_time_date.localeCompare(b.registed_time_date),
            sortOrder:
              sortedInfo.columnKey == "registed_time_date"
                ? sortedInfo.order
                : null,
            ellipsis: true,
          },
          {
            title: "시간",
            dataIndex: "registed_time_time",
            key: "registed_time_time",
            filterSearch: true,
            filters: registed_time_time_filter,
            filteredValue: filteredInfo.registed_time_time || null,
            onFilter: (value, record) => {
              const time = dayjs(record.registed_time_time, "HH:mm:ss");
              return time.isValid() && time.format("HH:mm:ss") === value;
            },
            sorter: (a, b) =>
              a.registed_time_time.localeCompare(b.registed_time_time),
            sortOrder:
              sortedInfo.columnKey == "registed_time_time"
                ? sortedInfo.order
                : null,
            ellipsis: true,
            render: (text) => {
              const time = dayjs(text, "HH:mm:ss");
              return time.isValid()
                ? time.format("HH:mm:ss")
                : "유효하지 않은 시간";
            },
          },
          {
            title: "객실명",
            dataIndex: "room_id",
            key: "room_id",
            render: (text, record, index) => {
              return (
                codeRoomList?.find((room) => room.key === text)?.value || ""
              );
            },
            filterSearch: true,
            filters: room_id_filter,
            filteredValue: filteredInfo.room_id || null,
            onFilter: (value, record) => record.room_id.includes(value),
            sorter: (a, b) => a.room_id.localeCompare(b.room_id),
            sortOrder:
              sortedInfo.columnKey == "room_id" ? sortedInfo.order : null,
            ellipsis: true,
          },
          {
            title: "상태변경내용",
            dataIndex: "changed_datas_name",
            key: "changed_datas_name",
            align: "center",
            filterSearch: true,
            filters: changed_datas_name_filter,
            filteredValue: filteredInfo.changed_datas_name || null,
            onFilter: (value, record) => record.changed_datas_name.includes(value),
            sorter: (a, b) => a.changed_datas_name.localeCompare(b.changed_datas_name),
            sortOrder: sortedInfo.columnKey == "changed_datas_name" ? sortedInfo.order : null,
            ellipsis: true,
          }
          // {
          //   title: "상태변경내용",
          //   dataIndex: "roomCondition",
          //   key: "roomCondition",
          //   filterSearch: true,
          //   filters: filteredRoomCondition,
          //   filteredValue: filteredInfo.roomCondition || null,
          //   onFilter: (value, record) => record.roomCondition.includes(value),
          //   sorter: (a, b) => a.roomCondition.localeCompare(b.roomCondition),
          //   sortOrder:
          //     sortedInfo.columnKey === "roomCondition" ? sortedInfo.order : null,
          //   ellipsis: true,
          // },
        ]);

        // console.log("columns");
        // console.log(columns);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [startDateTime, filteredInfo, sortedInfo]);

  const onChangeStartDatePicker = (date, dateString) => {
    if (dateString) {
      const endDateString = dateString + " 23:59:59";
      const startDateString = dateString + " 00:00:00";
      
      setEndDateTime(dayjs(endDateString));
      setStartDateTime(dayjs(startDateString));
    }
  };

  const excelDownload = () => {
    if(roomLogList.length > 0){
      const workbook = XLSX.utils.book_new();
      //const worksheet = XLSX.utils.table_to_sheet(document.getElementById("mainTable"));

      const headerOrder = [
        "날짜",
        "시간",
        "객실명",
        "상태변경내용"
      ];

      const ExcelDataList = roomLogList.map(item => ({
        "날짜" : item.registed_time_date,
        "시간" : item.registed_time_time,
        "객실명" : codeRoomList?.find((room) => room.key === item.room_id)?.value || "",
        "상태변경내용" : item.changed_datas_name,
      }));

      const worksheet = XLSX.utils.json_to_sheet(ExcelDataList, { header: headerOrder });
      
      const page_title = document.getElementById("page_title");
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, page_title.innerText + dayjs().format("_YYYYMMDD_HHmmss'") + ".xlsx");
    }
  };

  // 테이블 데이터 예시
  // const data = [
  //   {
  //     key: "1",
  //     name: "204",
  //     roomName: "일반실1",
  //     stayType: "대실",
  //     paymentDate: "2024-10-01 15:37",
  //   },
  //   {
  //     key: "2",
  //     name: "502",
  //     roomName: "일반실2",
  //     stayType: "숙박",
  //     paymentDate: "2024-10-01 11:20",
  //   },
  //   {
  //     key: "3",
  //     name: "307",
  //     roomName: "파티룸",
  //     stayType: "대실",
  //     paymentDate: "2024-09-20 01:22",
  //   },
  //   {
  //     key: "4",
  //     name: "306",
  //     roomName: "VIP",
  //     stayType: "숙박",
  //     paymentDate: "2024-08-26 16:10",
  //   },
  //   {
  //     key: "5",
  //     name: "502",
  //     roomName: "일반실2",
  //     stayType: "장기",
  //     paymentDate: "2024-10-07 19:51",
  //   },
  // ];

  // 객실명 필터
  //let filteredRoomId = [];
  // let filteredName = [...new Set(data.map((item) => item.name))].map(
  //   (name) => ({
  //     text: name,
  //     value: name,
  //   })
  // );

  // 유형명 필터
  //let filteredRoomTypeId = [];
  // let filteredRoomTypeId = [...new Set(data.map((item) => item.roomName))].map(
  //   (roomName) => ({
  //     text: roomName,
  //     value: roomName,
  //   })
  // );
  // 입실유형 필터
  //let filteredStayType = [];
  // let filteredStayType = [...new Set(data.map((item) => item.stayType))].map(
  //   (stayType) => ({
  //     text: stayType,
  //     value: stayType,
  //   })
  // );
  // 결제일자 필터
  // filteredPaymentDate = [];
  // let filteredPaymentDate = [
  //   ...new Set(
  //     data.map((item) => dayjs(item.paymentDate).format("YYYY-MM-DD"))
  //   ),
  // ].map((paymentDate) => ({
  //   text: paymentDate,
  //   value: paymentDate,
  // }));

  // 테이블 컬럼 생성

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setPagination(pagination);
  };
  // 필터 초기화 버튼
  const clearFilters = () => {
    setFilteredInfo({});
  };
  // 모두 초기화 버튼
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setPagination({ current: 1, pageSize: 15 });
  };
  // 날짜별 정렬 버튼
  const setPaymentDate = () => {
    setSortedInfo({
      order: "descend",
      columnKey: "payment_date",
    });
  };

  return (
    <div className="p-16 pb-0">
      <h2 id="page_title">객실 상태 변경 이력 조회</h2>

      <ConfigProvider locale={koKR}>
        <div className="flex-row flex-wrap gap-8 mt-16 mb-8">
          {/* <DatePicker
            showTime
            onChange={onChangeDatePicker}
            defaultValue={dayjs()}
            format="YYYY-MM-DD HH:mm:ss"
            style={{ minWidth: "8.75rem" }}
            placement={"bottomLeft"}
            disabledDate={disabledDate}
            disabledTime={disabledTime}
          /> */}

          <DatePicker
            // showTime
            onChange={onChangeStartDatePicker}
            defaultValue={dayjs(startDateTime)}
            format="YYYY-MM-DD"
            style={{ minWidth: "8.75rem" }}
            placement={"bottomLeft"}
            // disabledDate={disabledDate}
            // disabledTime={disabledTime}
          />

          <div className="btn-group ml-auto">
            <Button onClick={setPaymentDate}>날짜별 정렬</Button>
            <Button onClick={clearFilters}>전체 필터초기화</Button>
            <Button onClick={clearAll}>모두 초기화</Button>
            <Button onClick={excelDownload}>엑셀다운</Button>
          </div>
        </div>

        <div className="table-wrap-antd">
          <Table
            columns={columns}
            dataSource={roomLogList}
            onChange={handleChange}
            pagination={pagination}
            rowKey="key"
            scroll={{ y: "28.8rem", x: "100%" }}
            className="ant-table-respons"
            id={"mainTable"}
          />
        </div>
      </ConfigProvider>
    </div>
  );
};
