import React, { useState, useEffect } from "react";
import koKR from "antd/lib/locale/ko_KR";
import { roomSaleActivatedSearchApi, qrSmsSendApi } from "../api/api";
import { useCode } from "../login/CodeContext";
import { Button, ConfigProvider, Input, InputNumber, Table, Modal, message } from "antd";
import { AlertOutlined } from "@ant-design/icons";
import { handleInputNumberKeyDown, handleInputNumberOnInput } from "../util";

export const QRTextManage = () => {
  const [roomList, setRoomList] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });
  
  const [phoneNumber, setPhoneNumber] = useState("");
  
  // 공통코드
  const { coderead } = useCode();
  const code = coderead();
  const codeRoom = Object.entries(code.room);
  const codeRoomList = codeRoom.map(([key, value]) => {
    return {
      key: key,
      value: value.display_name,
    };
  });
  const codeRoomType = Object.entries(code.roomType);
  const codeRoomTypeList = codeRoomType.map(([key, value]) => {
    return {
      key: key,
      value: value.display_name,
    };
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await roomSaleActivatedSearchApi();
        const resArray = Object.entries(res.room_sales);
        const reveAllList = resArray.map(([key, value]) => {
          return {
            key: key,
            value: value,
            room_id: value.room_id,
            room_name: codeRoomList?.find((code) => code.key === value.room_id)?.value || "",
            room_type_id: value.room_type_id,
            //phone: value.phones[0],
            phone: (value.phones).join('')
          };
        }).sort((a, b) => b.room_name - a.room_name);;
        console.log(reveAllList);
        setRoomList(reveAllList);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleChange = (pagination, filters, sorter, e) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setPagination(pagination);
  };
  const handleInputChange = (key, column, value) => {
    const newData = roomList.map((item) => {
      if (item.key === key) {
        return { ...item, [column]: value }; // 해당 컬럼 업데이트
      }
      return item;
    });
    setRoomList(newData);
  };

  const handleSend = async (key) => {
    console.log(key);
    const sendData = roomList.find((data) => data.key === key);
    console.log(sendData);

    Modal.confirm({
      title: `[${sendData.phone}] 재발송 하시겠습니까?`,
      width: "440px",
      icon: (<AlertOutlined style={{ transform: "scale(1.3)", color: "#a46bff" }} />),
      okText: "예",
      cancelText: "아니오",
      centered: true,
      onOk: async () => {
        const res = await qrSmsSendApi(key, [sendData.phone]);
        console.log(res);
        if(key === Object.entries(res.rooms)[0][0]){
          message.info("삭제되었습니다.");
        } else {
          message.error("작업중 문제가 발생했습니다. 다시 시도해주세요.");
        }
      },
      onCancel() { },
    });
  };
  const columns = [
    {
      title: "No.",
      render: (text, record, index) => index + 1,
      width: "10%",
      align: "center",
    },
    {
      title: "객실명",
      dataIndex: "room_name",
      key: "room_name",
      align: "center",
    },
    {
      title: "객실유형",
      dataIndex: "room_type_id",
      key: "room_type_id",
      align: "center",
      render: (text) => {
        return (
            codeRoomTypeList?.find((code) => code.key === text)?.value || ""
        );
      },
    },
    {
      title: "휴대폰번호",
      dataIndex: "phone",
      key: "phone",
      render: (_, record) => {
        return (
          <Input
            type="phone"
            defaultValue={record.phone}
            maxLength={11}
            onChange={(e) => handleInputChange(record.key, "phone", e.target.value)}
            onKeyDown={handleInputNumberKeyDown}
            onInput={handleInputNumberOnInput}
          />
        );
      },
      align: "center",
    },
    {
      title: "발송상태",
      dataIndex: "status",
      key: "status",
      align: "center",
    },
    {
      title: "재발송",
      key: "action",
      render: (_, record) => (
        <Button type="primary" size="small" onClick={() => handleSend(record.key)}>
          재발송
        </Button>
      ),
      width: "10%",
      align: "center",
    },
  ];

  return (
    <div className="p-16 pb-0">
      <h2 className="mb-16">QR/OTP SMS 발송 관리</h2>

      <ConfigProvider locale={koKR}>
        <div className="table-wrap-antd">
          <Table
            columns={columns}
            dataSource={roomList}
            onChange={handleChange}
            pagination={pagination}
            rowKey="key"
            scroll={{ y: "32.5rem" }}
            className="ant-table-respons"
          />
        </div>
      </ConfigProvider>
    </div>
  );
};
