import React, {useState, useEffect} from "react";
import { Table, Button, ConfigProvider, Select, message, Modal} from "antd";
import { AlertOutlined,
  } from "@ant-design/icons";
import koKR from "antd/lib/locale/ko_KR";
import { useCode } from "../login/CodeContext";
import { roomAllByAccomSearchApi, roomSaleUpdateApi } from "../api/api";

export const ModalRoomSelecter= ({roomSaleId, closeModal, refreshRoom}) => {
    const [roomAllList, setRoomAllList] = useState([]);
    const [filteredData, setFilteredData] = useState();
    //const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });

    // 공통 코드 불러오는 부분
    const { coderead } = useCode();
    const code = coderead();
    const codeRoomType = Object.entries(code.roomType);
    const codeRoomTypeList = codeRoomType.map(([key, value]) => {
    return {
        key: key,
        value: value.display_name,
    };
    });

    // Room all-by-accom : accom에 등록된 모든 room 정보
    useEffect(() => {
        const fetchData = async () => {
        try {
            const res = await roomAllByAccomSearchApi();
            const resArray = Object.entries(res.rooms);
            const roomAllArray = resArray
            .map(([key, value, no]) => {
                return {
                key: key,
                value: value,
                no: value.display_name,
                roomTypeId: value.room_type_id,
                roomSaleId: value.room_sale_id,
                };
            })
            .sort((a, b) => b.no - a.no);
            setRoomAllList(roomAllArray);
            setFilteredData(roomAllArray);
            console.log("roomAllArray ==> ", roomAllArray);
        } catch (error) {
            console.log(error);
        }
        };
        fetchData();
    }, []);

    const moveRoomFunc = (room) => {
        console.log("move room => ", room);
        console.log("roomSaleId => ", roomSaleId);

        Modal.confirm({
            title: `[${room.no}]로 객실이동 하시겠습니까?`,
            width: "440px",
            icon: (
              <AlertOutlined
                style={{ transform: "scale(1.3)", color: "#a46bff" }}
              />
            ),
            okText: "예",
            cancelText: "아니오",
            centered: true,
            onOk: async () => {
              const param = {
                room_id: room.key,
              };
  
              try {
                const res = await roomSaleUpdateApi(roomSaleId, param);
                console.log(res);
                if (roomSaleId === Object.entries(res.room_sales)[0][0]) {
                  message.info("변경되었습니다.");
                  closeModal();
                  refreshRoom();
                } else {
                  message.error(
                    "변경중 문제가 발생했습니다. 잠시후 다시 작업해주세요."
                  );
                }
              } catch (error) {
                if(error.response.data){
                  message.error("변경중 문제가 발생했습니다. code : " + error.status + " message: " + error.response.data.message);
                }
                console.log(error);
              }
            },
            onCancel() {},
        });        
    }
    
    // 테이블 컬럼 생성
    const columns = [
        {
            title: "객실명",
            dataIndex: "no",
            key: "no",
            width: "35%",
            align: "center",
        },
        {
            title: "타입명",
            dataIndex: "roomTypeId",
            key: "roomTypeId",
            width: "35%",
            align: "center",
            render: (text) => {
                return (
                    codeRoomTypeList?.find((code) => code.key === text)?.value || ""
                );
            },
        },
        {
            title: '',
            key: 'operation',
            width: "30%",
            render: (record) => <Button onClick={() => moveRoomFunc(record)}>선택</Button>,
        },
    ];

    //const handleChange = (pagination, filters, sorter) => {
    //    setPagination(pagination);
    //};

    const handleSelectChange = (value) => {
        console.log(value);
        const filtered = roomAllList.filter((item) => item.roomTypeId === value.key);
        setFilteredData(filtered);
    };

    return (
      <>
        <ConfigProvider locale={koKR}>
          <div className="flex-row flex-wrap gap-8 mt-16 mb-8">
            <Select
              placeholder="객실타입"
              options={codeRoomTypeList}
              style={{
                width: "100%",
                minWidth: "100px",
                maxWidth: "160px",
                textAlign: "left",
              }}
              onChange={(key, value) => handleSelectChange(value)}
            ></Select>
          </div>

          <div className="table-wrap-antd">
            <Table
              columns={columns}
              dataSource={filteredData}
              //onChange={handleChange}
              pagination={false}
              rowKey="key"
              scroll={{
                y: "28.8rem",
                x: "300px",
              }}
              className="ant-table-respons"
            />
          </div>
        </ConfigProvider>
      </>
    );
  };
  