import {
  Button,
  ConfigProvider,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Select,
  message,
  Modal,
} from "antd";
import React, { useState, useEffect, forwardRef, useImperativeHandle, } from "react";
import { useCode } from "../login/CodeContext";
import dayjs from "dayjs";
import koKR from "antd/lib/locale/ko_KR";
import {
  handleInputNumberKeyDown,
  handleInputNumberOnInput,
  formatNumber,
  disabledDate,
  disabledBeforeDate,
  disabledDateThreeMonthsAgo,
  disabledTime,
  DateTimeFormatterSymbol,
} from "../util";
import { CloseOutlined, AlertOutlined } from "@ant-design/icons";
import { 
  roomTypeInfoOnlyApi,
  roomAllByAccomSearchApi,
  roomReserveSelectOneApi, 
  roomReserveUpdateApi, 
  roomReserveInsertApi,  
  roomReserveDeleteApi } from "../api/api";

export const ReserveRegistrationModal = forwardRef(({pReserveNo, closeModal}, ref) => {
  // 변수
  const [stateType, setStateType] = useState("");               //투숙형태
  const [stayType, setStayType] = useState("DAYS");             //투숙형태
  const [agent, setAgent] = useState("FRONT");                  //예약방법
  const [roomTypeId, setRoomTypeId] = useState("");             //객실유형
  const [roomId, setRoomId] = useState("");                     //객실배정
  const [checkInSched, setCheckInSched] = useState(dayjs());    //입실예정
  const [checkOutSched, setCheckOutSched] = useState(dayjs());  //퇴실예정
  const [phone, setPhone] = useState("");                       //휴대폰
  const [reserveNo, setReserveNo] = useState("");               //예약번호
  const [reserveName, setReserveName] = useState("");           //예약자명
  const [visitType, setVisitType] = useState("ON_FOOT");        //방문유형
  const [fee, setFee] = useState(0);                            //판매가
  const [prepaid, setPrepaid] = useState(0);                    //선불금액
  const [memo, setMemo] = useState("");                         //메모
  const [roomSelectArr, setRoomSelectArr] = useState([]);
  const [roomAllList, setRoomAllList] = useState([]);

  
  // 공통 코드 불러오는 부분
  const { coderead } = useCode();
  const code = coderead();
  const codeRoomType = Object.entries(code.roomType);
  const codeRoomTypeList = codeRoomType.map(([key, value]) => {
    return { key: key, value: value.display_name };
  });
  const codeRoomTypeList_selectbox = codeRoomType.map(([key, value]) => {
    return { value: key, label: value.display_name };
  });
  const codeAgentType = Object.entries(code.agentType);
  const codeAgentTypeList = codeAgentType.map(([key, value]) => {
    return { key: key, value: value };
  });
  const codeAgentTypeList_selectbox = codeAgentType.map(([key, value]) => {
    return { value: key, label: value };
  });
  const codeState = Object.entries(code.state);
  const codeStateList = codeState.map(([key, value, label]) => {
    return {
      key: key,
      value: key,
      label: value,
    };
  });
  
  // Room all-by-accom : accom에 등록된 모든 room 정보
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await roomAllByAccomSearchApi();
        const resArray = Object.entries(res.rooms);
        const roomAllArray = resArray.map(([key, value]) => {
            return {
              key: key,
              value: value,
              type : value.room_type_id,
              no : value.display_name
            };
          })
          .sort((a, b) => b.no - a.no);
        setRoomAllList(roomAllArray);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  
  //const [reveAllList, setReveAllList] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await roomReserveSelectOneApi(pReserveNo);
        const resArray = Object.entries(res.room_reserves);
        console.log(resArray[0][1]);
        setStateType(resArray[0][1].state);
        setStayType(resArray[0][1].stay_type);
        setAgent(resArray[0][1].agent);
        setRoomTypeId(resArray[0][1].room_type_id);
        setRoomId(resArray[0][1].room_id);
        setCheckInSched(resArray[0][1].check_in_sched);
        setCheckOutSched(resArray[0][1].check_out_sched);
        setPhone(resArray[0][1].phone);
        setReserveNo(resArray[0][1].reserve_no);
        setReserveName(resArray[0][1].name);
        setVisitType(resArray[0][1].visit_type);
        setFee(resArray[0][1].fee);
        setPrepaid(resArray[0][1].prepaid);
        setMemo(resArray[0][1].memo);

      } catch (error) {
        console.log(error);
      }
    };
    if(pReserveNo!== ""){
      fetchData();
    }else{
      setStateType("");
      setStayType("DAYS");
      setAgent("FRONT");
      setRoomTypeId("");
      setRoomId("");
      setCheckInSched(dayjs());
      setCheckOutSched(dayjs());
      setPhone("");
      setReserveNo("");
      setReserveName("");
      setVisitType("ON_FOOT");
      setFee(0);
      setPrepaid(0);
      setMemo("");
    }
  }, [pReserveNo]);

  const [roomTypeMap, setRoomTypeMap] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await roomTypeInfoOnlyApi(roomTypeId);
        const resArray = Object.entries(res.room_types);
        setRoomTypeMap(resArray[0][1]);
        console.log("roomTypeMap ==>", resArray[0][1]);
      } catch (error) {
        console.log(error);
      }
    }
    if(roomTypeId !== ""){
      fetchData();
      setRoomSelectArr(
        roomAllList.filter((room) => room.type === roomTypeId).map((item) => {
          return {
            value: item.key,
            label: item.value.display_name,
          };
        })
      );
    }else{
      setRoomSelectArr(
        roomAllList.map((item) => {
          return {
            value: item.key,
            label: item.value.display_name,
          };
        })
      );
    }
  }, [roomTypeId, roomAllList]);

  useEffect(() => {
    if(stayType === "DAYS" || stayType === "LONG_DAYS"){
      const weekprc = roomTypeMap.days_pricing;
      
      weekprc && weekprc.map((prc) => {
        if(prc.use_front_manager && prc.day_of_week[0].substr(0, 3) === dayjs(checkInSched).format('ddd').toUpperCase()){
          setFee(prc.basic_price);
          setPrepaid(prc.basic_price);
        }
      });
    }
    if(stayType === "HOURS"){
      const hourprc = roomTypeMap.times_pricing;
      
      let checkinDate = dayjs(checkInSched).format("YYYY-MM-DD");
      hourprc && hourprc.map((prc) => {
        if(prc.use_front_manager 
          && prc.day_of_week[0].substr(0, 3) === dayjs(checkInSched).format('ddd').toUpperCase()
          && dayjs(checkinDate + " " + prc.sellable_time_range[0].start_at + ":00").valueOf() < dayjs(checkInSched).valueOf()
          && dayjs(checkInSched).valueOf() < dayjs(checkinDate + " " + prc.sellable_time_range[0].end_before + ":00").valueOf()
        ){
          //console.log("prc 요일 ==>", prc.day_of_week[0].substr(0, 3));
          
          setFee(prc.basic_price);
          setPrepaid(prc.basic_price);
        }
      });
    }
  }, [roomTypeMap, stayType, checkInSched]);
  
  const onChangeRadio_stayType = (e) => {
    setStayType(e.target.value);
  };
  
  // selectbox 검색
  const onSearchSelect = (value) => {
    console.log("search:", value);
  };
  // 대행사 selectbox
  const handleChangeAgency = (value) => {
    console.log("selected : ", value);
    setAgent(value);
  };
  // 객실 유형 selectbox
  const handleChangeType = (value) => {
    console.log("selected : ", value);
    setRoomTypeId(value);
  };
  // 객실 배정 selectbox
  const handleChangeRoomHo = (value) => {
    console.log("selected : ", value);
    setRoomId(value);
  };

  // datePicker
  const handleChangeDatePicker_CheckIn = (date, dateString) => {
    console.log(dateString);
    setCheckInSched(dayjs(dateString));
  };
  
  const handleChangeDatePicker_CheckOut = (date, dateString) => {
    console.log(dateString);
    setCheckOutSched(dayjs(dateString));
  };

  const handleChangeDatePicker = (date, dateString) => {
    console.log(date, dateString);
  };

  // 전화번호 : Input
  const handleInputChange_Phone = (e) => {
    setPhone(e.target.value);
  };

  const handleInputChange_ReserveName = (e) => {
    setReserveName(e.target.value);
  };
  
  // 방문 유형 : Radio
  const onChangeRadio_VisitType = (e) => {
    console.log("radio checked", e.target.value);
    setVisitType(e.target.value);
  };

  // 판매가 : NumberInput
  const onChangeInputNumber_Fee = (value) => {
    console.log("판매가", value);
    setFee(value);
  };

  const onChangeInputNumber_Prepaid = (value) => {
    console.log("판매가", value);
    setPrepaid(value);
  };
  
  // 메모 : Input
  const handleInputChange = (e) => {
    setMemo(e.target.value);
  };
  const handleClearInput = () => {
    setMemo("");
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    usedInParentComponent,
  }));

  const usedInParentComponent = async () => {
    //정합성 체크
    if(phone !== ""){
      if(phone.length < 4){ message.error("전화번호를 확인해주세요."); return false;}
    }
    if(dayjs(checkInSched).valueOf() > dayjs(checkOutSched).valueOf()){ message.error("입실/퇴실 예정일시를 확인해주세요."); return false;}

    //데이터맵 설정
    const param = {
      "room_id": roomId,
      "room_type_id": roomTypeId,
      //"ota_room_name": "string",
      "check_in_sched": dayjs(checkInSched).valueOf(),
      "check_out_sched": dayjs(checkOutSched).valueOf(),
      "name": reserveName,
      //"phone": phone,
      "agent": agent,
      //"used_unmanned": true,
      //"generated_unmanned": true,
      //"state": "GENERATED",
      //"reserve_no": reserveNo,
      "visit_type": visitType,
      "stay_type": stayType,
      "prepaid": prepaid,
      "fee": fee,
      "memo": memo
    };
    if(phone!==""){
      param.phone = phone;
    }
    console.log(param);
    
    try {
      if (pReserveNo !== "") {
        const res = await roomReserveUpdateApi(pReserveNo, param);
        console.log(res);
        if (pReserveNo === Object.entries(res.room_reserves)[0][0]) {
          message.info("저장되었습니다.");
        } else {
          message.error("저장중 문제가 발생했습니다. 다시 저장해주세요.");
        }
      } else {
        const res = await roomReserveInsertApi(param);
        console.log(res);
        if (Object.entries(res.room_reserves).length > 0) {
          message.info("저장되었습니다.");
        } else {
          message.error("저장중 문제가 발생했습니다. 다시 저장해주세요.");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const delectReserve = () => {
    Modal.confirm({
      title: "삭제하시겠습니까?",
      width: "440px",
      icon: (<AlertOutlined style={{ transform: "scale(1.3)", color: "#a46bff" }} />),
      okText: "예",
      cancelText: "아니오",
      centered: true,
      onOk: async () => {
        const res = await roomReserveDeleteApi(pReserveNo);

        if (pReserveNo === Object.entries(res.room_reserves)[0][0]) {
          message.info("삭제되었습니다.");
          closeModal();
        } else {
          message.error("삭제 중 문제가 발생했습니다. 다시 시도해주세요.");
        }
      },
      onCancel() { },
    });
  };

  return (
    <>
      <ConfigProvider locale={koKR}>
        <div
          className="resRegModal grid-col-2 p-16"
          style={{ borderTop: "1px solid #efefef" }}
        >
          <div className="flex-row">
            <h3 className="label">투숙 형태 &nbsp;:</h3>
            <div className="btn-group text-l">
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                className="room-stay-type"
                defaultValue={stayType}
                onChange={onChangeRadio_stayType}
              >
                <Radio name="stay_type" value={"HOURS"} label={"HOURS"}>
                  대실
                </Radio>
                <Radio name="stay_type" value={"DAYS"} label={"DAYS"}>
                  숙박
                </Radio>
                <Radio name="stay_type" value={"LONG_DAYS"} label={"LONG_DAYS"}>
                  장기
                </Radio>
              </Radio.Group>
            </div>
          </div>

          <div className="flex-row">
            <h3 className="label">
              상<span className="mr-32"></span>태 &nbsp;:
            </h3>
            <div className="flex-row flex-wrap w-full gap-8">
              <h2 className="text-purple">
                {codeStateList?.find((code) => code.key === stateType)?.label || "예약 등록"}
              </h2>
              <Button
                type="primary"
                htmlType="button"
                className="ml-auto"
                ghost
                onClick={delectReserve}
                //disabled
              >
                예약취소
              </Button>
            </div>
          </div>

          <div className="flex-row">
            <h3 className="label">예약 방법 &nbsp;:</h3>
            <Select
              showSearch
              placeholder="선택하세요"
              value={agent}
              optionFilterProp="label"
              onChange={(value) => handleChangeAgency(value)}
              onSearch={onSearchSelect}
              placement={"bottomLeft"}
              //value={codeAgentTypeList_selectbox.value}
              options={codeAgentTypeList_selectbox}
              className="w-full mr-32"
            />
          </div>

          <div className="flex-row">
            <h3 className="label">수정 일시 &nbsp;:</h3>
            <DatePicker
              showTime
              onChange={(value) => handleChangeDatePicker(value)}
              style={{ minWidth: "8.75rem" }}
              className="mr-32 w-full readonly"
              format="YYYY-MM-DD HH:mm:ss"
              defaultValue={dayjs()}
              disabled //비활성이지만 readonly상태 : className에 readonly 추가 필수
              disabledDate={disabledDate}
              onKeyDown={handleInputNumberKeyDown}
              onInput={handleInputNumberOnInput}
              placement={"topLeft"}
            />
          </div>

          <div className="flex-row">
            <h3 className="label">객실 유형 &nbsp;:</h3>
            <Select
              showSearch
              placeholder="선택해주세요"
              optionFilterProp="label"
              onChange={(value) => handleChangeType(value)}
              //onSearch={onSearchSelect}
              //placement={"bottomLeft"}
              value={roomTypeId}
              options={codeRoomTypeList_selectbox}
              className="w-full mr-32"
            />
          </div>

          <div className="flex-row">
            <h3 className="label">객실 배정 &nbsp;:</h3>
            <Select
              showSearch
              placeholder="선택해주세요"
              value={roomId}
              optionFilterProp="label"
              onChange={(value) => handleChangeRoomHo(value)}
              //onSearch={onSearchSelect}
              //placement={"bottomLeft"}
              // value={codeRoomTypeList_selectbox.value}
              options={roomSelectArr}
              className="w-full mr-32"
            />
          </div>

          <div className="flex-row">
            <h4 className="label pt-4 pr-10">입실예정일 &nbsp;:</h4>
            <DatePicker
              showTime
              onChange={handleChangeDatePicker_CheckIn}
              style={{ minWidth: "8.75rem" }}
              className="mr-32 w-full"
              format="YYYY-MM-DD HH:mm:ss"
              value={dayjs(checkInSched)}
              onKeyDown={handleInputNumberKeyDown}
              onInput={handleInputNumberOnInput}
              placement={"topLeft"}
              disabledDate={pReserveNo? null : disabledBeforeDate}
            />
          </div>

          <div className="flex-row">
            <h4 className="label pt-4 pr-10">퇴실예정일 &nbsp;:</h4>
            <DatePicker
              showTime
              onChange={handleChangeDatePicker_CheckOut}
              style={{ minWidth: "8.75rem" }}
              className="mr-32 w-full"
              format="YYYY-MM-DD HH:mm:ss"
              value={dayjs(checkOutSched)}
              onKeyDown={handleInputNumberKeyDown}
              onInput={handleInputNumberOnInput}
              placement={"topLeft"}
              disabledDate={pReserveNo? null : disabledBeforeDate}
            />
          </div>

          <div className="flex-row">
            <h3 className="label">전화번호 &nbsp;:</h3>
            <Input
              placeholder="-없이"
              value={phone}
              onChange={handleInputChange_Phone}
              onKeyDown={handleInputNumberKeyDown}
              className="mr-32"
            />
          </div>

          <div className="flex-row">
            <h3 className="label">예약 번호 &nbsp;:</h3>
            <Input
              placeholder="예약번호"
              value={reserveNo}
              className="mr-32"
            />
          </div>

          <div className="flex-row">
            <h3 className="label mr-10">고&nbsp;객&nbsp;명 &nbsp;:</h3>
            <Input
              placeholder="고객명"
              value={reserveName}
              onChange={handleInputChange_ReserveName}
              className="mr-32"
            />
          </div>

          <div className="flex-row">
            <h3 className="label">방문 유형 &nbsp;:</h3>
            <Radio.Group
              onChange={onChangeRadio_VisitType}
              value={visitType}
              className="mt-4"
            >
              <Radio value="ON_FOOT">도보방문</Radio>
              <Radio value="ON_CAR">차량방문</Radio>
            </Radio.Group>
          </div>

          <div className="flex-row">
            <h3 className="label mr-10">판&nbsp;매&nbsp;가 &nbsp;:</h3>
            <InputNumber
              min={0}
              max={999999999999}
              step={5000}
              style={{ maxWidth: "10.625rem", minWidth: "7.625rem" }}
              value={fee}
              // value={}
              formatter={formatNumber}
              onChange={onChangeInputNumber_Fee}
              onKeyDown={handleInputNumberKeyDown}
              onInput={handleInputNumberOnInput}
            />
          </div>

          <div className="flex-row">
            <h3 className="label">OTA 결제 &nbsp;:</h3>
            <InputNumber
              min={0}
              max={999999999999}
              step={5000}
              style={{ maxWidth: "10.625rem", minWidth: "7.625rem" }}
              value={prepaid}
              // value={}
              formatter={formatNumber}
              onChange={onChangeInputNumber_Prepaid}
              onKeyDown={handleInputNumberKeyDown}
              onInput={handleInputNumberOnInput}
            />
          </div>

          <div className="flex-row grid-span-2">
            <h3 className="label">고객메모 &nbsp;:</h3>
            <Input
              maxLength={100}
              count={{
                show: true,
                max: 100,
              }}
              placeholder="최대 100자"
              value={memo}
              onChange={handleInputChange}
            />
            <Button
              type="primary"
              ghost
              htmlType="button"
              className="ml-8"
              style={{ padding: "0 1.125rem" }}
              icon={<CloseOutlined />}
              onClick={handleClearInput}
            ></Button>
          </div>

          {/*  */}
        </div>
      </ConfigProvider>
    </>
  );
});
